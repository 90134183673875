import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feedfooterActions } from "../../actions";
import { createMarkup, useScript } from "../../../helpers";
import TextContentHeader from "../Utils/TextContentHeader";
import { userActions } from "../../../Member/actions";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
function FooterSection() {
    useScript("info/smartmenu/jquery.smartmenus.js");

    const feedFooter = useSelector((state) => state.feedFooter);
    const feedManu = useSelector((state) => state.feedManu);
    const authentication = useSelector((state) => state.authentication);
    const feedData = useSelector((state) => state.feedData);
    // const state = useSelector(state => state);
    const dispatch = useDispatch();

    // const [loading, setLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // async function feedData() {
        //     await dispatch(
        //         feedfooterActions.feedfooterData(`/api/info/infofooter`)
        //     );
        // }
        // feedData();
    }, []);
    useEffect(() => {
        if (feedFooter.fetchSuccess) {
            setLoading(false);
        }
        return async function cleanup() {
            await setLoading(true);
        };
    }, [feedFooter.fetchSuccess]);

    const constaintLinks = [
        { url: "/show/261", detail: "ประวัติสหกรณ์" },
        { url: "/show/634", detail: "ผลการดำเนินงาน" },
        { url: "/list/download", detail: "ดาวน์โหลดแบบฟอร์ม" },
        { url: "/list/rag", detail: "กฏและระเบียบของสหกรณ์" },
    ];

    // const servinceLinks = [
    //     { url: "/show/1238", detail: "- การสมัครสมาชิกสามัญ" },
    //     { url: "/show/1239", detail: "- การสมัครสมาชิกสมทบ" },
    //     { url: "/list/service_share", detail: "- ทุนเรือนหุ้น" },
    //     { url: "/show/717", detail: "- สวัสดิการ" },
    //     { url: "/show/1240", detail: "- เงินฝาก" },
    //     { url: "/list/service_loan", detail: "- เงินกู้" },
    //     { url: "https://www.soatsolution.com/Cal_loan", detail: "- คำนวณเงินกู้" },
    //     { url: "/show/1244", detail: "- การสมัคร mobile" },
    //     { url: "/list/service_cremation", detail: "- สมาคมฌาปนกิจสงเคราะห์" },
    // ];
    // const Address =
    //     "111 หมู่ 8 บ้านตะกุดขอน ถนนเพชรมาตุคลา ตำบลท่าช้าง อำเภอเฉลิมพระเกียรติ ";
    // const AddressSub = "จังหวัดนครราชสีมา 30230";
    const Address = "เลขที่ 1488 ถนนนครไชยศรี แขวงถนนนครไชยศรี เขตดุสิต";
    const AddressSub = "กรุงเทพมหานคร 10300";
    const PhoneCall = [{ label: "0 2 241 5600 ถึง 19", data: "022415600" }];
    const PhoneLabel = [
        { label: "ต่อเบอร์ : 51281", data: "ผู้จัดการสหกรณ์" },
        {
            label: "ต่อเบอร์ : 51245",
            data: "งานเงินกู้สามัญ (คุณอดุลย์) และงานเงินกู้ฉุกเฉิน (คุณธนวรรณ)",
        },
        { label: "ต่อเบอร์ : 51249", data: "งานการเงิน รับ-จ่าย (คุณสุริศา)" },
        {
            label: "ต่อเบอร์ : 51250",
            data: "งานสวัสดิการ สิทธิประโยชน์สมาชิก (คุณวรรณลี) และงานเงินกู้สามัญ (คุณพรทิพย์)",
        },
        {
            label: "ต่อเบอร์ : 51282",
            data: "งานทะเบียนสมาชิก (คุณอารดา) และงานระบบเรียกเก็บเงิน (คุณวรวรรธน์)",
        },
    ];

    const Email = [
        { label: "", data: "" },
        // { label: "koratngos@hotmail.com", data: "koratngos@hotmail.com" },
        // { label: "info@koratngos.com", data: "info@koratngos.com" },
    ];

    const SocialMedia = [
        {
            label: "Facebook",
            data: "https://facebook.com/%E0%B8%AA%E0%B8%AB%E0%B8%81%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%AD%E0%B8%AD%E0%B8%A1%E0%B8%97%E0%B8%A3%E0%B8%B1%E0%B8%9E%E0%B8%A2%E0%B9%8C%E0%B8%82%E0%B9%89%E0%B8%B2%E0%B8%A3%E0%B8%B2%E0%B8%8A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%81%E0%B8%A3%E0%B8%A1%E0%B8%AA%E0%B8%A3%E0%B8%A3%E0%B8%9E%E0%B8%AA%E0%B8%B2%E0%B8%A1%E0%B8%B4%E0%B8%95-%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94-100654695015197",
            image: "mediafiles/images/Utils/facebook.svg",
        },
        {
            label: "Line",
            data: "",
            image: "mediafiles/images/Utils/line.png",
        },
        {
            label: "Coop Google Map Location",
            data: "https://goo.gl/maps/fL2wUf1y96mTsJk46",
            image: "/mediafiles/images/Utils/map.png",
        },
    ];

    const ApplicationDownload = [
        {
            label: "iOS App Store",
            data: "",
            image: "mediafiles/images/Utils/apple.png",
        },
        {
            label: "Android PLay Store",
            data: "",
            image: "mediafiles/images/Utils/google.png",
        },
    ];
    return (
        <Fragment>
            {loading && <Fragment>&nbsp;</Fragment>}
            {!loading && (
                <footer className="rounded-sm   ">
                    <section
                        id="footer"
                        className="bg-gradient-to-r from-customblue-1 to-blue-400 "
                    >
                        <div className="max-w-7xl mx-auto py-3">
                            <div className="grid grid-cols-12 gap-4 px-2 text-center md:text-left">
                                <div className="col-span-12 md:col-span-3 lg:col-span-4 border-b pb-4 md:border-0 md:pb-0 intro-y">
                                    <TextContentHeader
                                        text="เมนูหลัก"
                                        className="py-2 md:text-lg lg:text-2xl xl:text-2xl text-white"
                                    />
                                    {/* <div className="border-b-2 border-white my-1"></div> */}
                                    <ul className="list-disc list-inside">
                                        {constaintLinks.map((val, i) => {
                                            return (
                                                <li
                                                    className="list-none intro-y"
                                                    key={i}
                                                >
                                                    <a
                                                        href={`${val.url}`}
                                                        className="text-white text-sm md:text-base lg:text-lg 
                                                        "
                                                    >
                                                        <p className="transition duration-300 ease-in-out transform hover:translate-x-2">
                                                            {val.detail}
                                                        </p>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div className="col-span-12 md:col-span-9 lg:col-span-5 border-b pb-4 md:border-0 md:pb-0 intro-y">
                                    <TextContentHeader
                                        text="ที่อยู่"
                                        className="py-2 md:text-lg lg:text-2xl xl:text-2xl text-white "
                                    />
                                    <div className="intro-y">
                                        <div
                                            id="BoxtabDep"
                                            className="text-white text-sm md:text-base lg:text-lg "
                                        >
                                            <p>อาคาร เฉลิมพระเกียรติ ชั้น 6</p>
                                            <p>องค์การสงเคราะห์ทหารผ่านศึก</p>
                                            <p>
                                                420/3 ถนนราชวีถี แขวงทุ่งพญาไท
                                            </p>
                                            <p>
                                                เขตราชเทวี กรุงเทพมหานคร 104000
                                            </p>
                                            {/* <p>โทร 02-354-8587 ต่อ 370 , 379</p> */}
                                            <p>โทร/แฟกส์ 026446015</p>
                                            <p>
                                                โทรศัพท์มือถือ/ID LINE
                                                082-4456060
                                            </p>
                                            {/* <a
                                                href="https://goo.gl/maps/4i51CrShZvkmLQhS9"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                &gt;&gt; Google Map {"<<"}
                                            </a> */}
                                        </div>
                                    </div>
                                    {/* <div className="my-1 py-1"> */}
                                    {/* <h3 className="text-white my-1 py-1 text-sm md:text-sm lg:text0-base xl:text-lg flex"> */}
                                    {/* <a
                                                href="https://goo.gl/maps/M2kjc6uyFZE9SBRT8"
                                                className=""
                                                rel="noreferrer noopener"
                                                target="_blank"
                                            >
                                                <img
                                                    className="rounded-full w-12 h-12"
                                                    alt={`coop google map`}
                                                    src="/mediafiles/images/Utils/map.png"
                                                />
                                            </a>{" "} */}
                                    {/* {window.config.coopNameTH}
                                        </h3> */}
                                    {/* <h3 className="text-white my-1 py-1 text-sm md:text-sm lg:text0-base xl:text-lg">
                                            {Address}
                                        </h3>
                                        <h3 className="text-white my-1 py-1 text-sm md:text-sm lg:text0-base xl:text-lg">
                                            {AddressSub}
                                        </h3> */}

                                    {/* <div className="my-1 py-1">
                                            <ul> */}
                                    {/* ติดต่อเรา */}
                                    {/* {feedManu.fetchSuccess &&
                                                    feedManu.data
                                                        .tel_footerweb &&
                                                    feedManu.data
                                                        .tel_footerweb[0] &&
                                                    feedManu.data.tel_footerweb.map(
                                                        (item, i) => {
                                                            return (
                                                                <li
                                                                    className="intro-y"
                                                                    key={i}
                                                                >
                                                                    <a
                                                                        href={`${item.Note}`}
                                                                        rel="noreferrer"
                                                                        className="flex text-white hover:font-medium font-normal text-xs md:text-sm lg:text-base"
                                                                    >
                                                                        <PhoneIcon className="w-6 h-6 text-white" />{" "}
                                                                        &nbsp;
                                                                        {
                                                                            item.Question
                                                                        }
                                                                    </a>
                                                                </li>
                                                            );
                                                        }
                                                    )} */}

                                    {/* {PhoneCall.map((item) => {
                                                    return (
                                                        <li className="intro-y">
                                                            <a
                                                                href={`tel:${item.data}`}
                                                                className="flex text-white hover:font-medium font-normal text-xs md:text-sm lg:text-base"
                          s                                  >
                                                                <PhoneIcon className="w-6 h-6 text-white" />{" "}
                                                                &nbsp;
                                                                {item.label}
                                                            </a>
                                                        </li>
                                                    );
                                                })} */}
                                    {/* {PhoneLabel.map((item) => {
                                                    return (
                                                        <li className="intro-y flex text-white hover:font-medium font-normal text-xs md:text-sm lg:text-sm xl:text-base">
                                                            {item.label}&nbsp;
                                                            <i class="fas fa-user text-white-800"></i>
                                                            &nbsp;{item.data}
                                                        </li>
                                                    );
                                                })} */}
                                    {/* </ul> */}
                                    {/* </div> */}
                                    {/* <div className="my-1 py-1">
                                            <ul>
                                                {Email.map((item, i) => {
                                                    return (
                                                        <li
                                                            className="intro-y"
                                                            key={i}
                                                        >
                                                            <a
                                                                // href={`${item.data}`}
                                                                href="javascript:void(0)"
                                                                className="flex text-white hover:font-medium font-normal text-xs md:text-sm lg:text-base"
                                                            >
                                                                <MailIcon className="w-6 h-6 text-white" />{" "}
                                                                &nbsp;
                                                                {item.label}
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div> */}
                                    {/* </div> */}
                                </div>
                                <div className="col-span-12 md:col-span-12 lg:col-span-3 border-b pb-4 md:border-0 md:pb-0 intro-y">
                                    {/* <TextContentHeader
                                        text="ติดตามเรา"
                                        className="py-2 md:text-lg lg:text-2xl xl:text-2xl text-white "
                                    />
                                    <div className="border-b-2 border-white my-1"></div>
                                    <ul className="flex">
                                        {SocialMedia.map((val, i) => {
                                            return (
                                                <li
                                                    className="mx-2 intro-y"
                                                    key={i}
                                                >
                                                    <a
                                                        className=""
                                                        href={
                                                            val.data == ""
                                                                ? "javascript:void(0)"
                                                                : val.data
                                                        }
                                                        title={val.label}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        <img
                                                            alt={val.label}
                                                            src={val.image}
                                                            className="w-14 h-14 rounded-full shadow"
                                                        />
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul> */}
                                    {/* <div className="my-2 py-1"></div> */}
                                    <TextContentHeader
                                        text="DOWNLOAD APP"
                                        className="py-2 md:text-lg lg:text-2xl xl:text-2xl text-white "
                                    />
                                    {/* <div className="border-b-2 border-white my-1"></div> */}
                                    <div>
                                        <ul className="">
                                            {ApplicationDownload.map(
                                                (val, i) => {
                                                    return (
                                                        <li
                                                            className="mx-2 py-1 intro-y"
                                                            key={i}
                                                        >
                                                            <a
                                                                className=""
                                                                // href={
                                                                //     val.data ==
                                                                //     ""
                                                                //         ? "javascript:void(0)"
                                                                //         : val.data
                                                                // }
                                                                title={
                                                                    val.label
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer noopener"
                                                            >
                                                                <img
                                                                    alt={
                                                                        val.label
                                                                    }
                                                                    src={
                                                                        val.image
                                                                    }
                                                                    className="w-auto h-14  rounded-xl shadow
                                                                    transition duration-300 ease-in-out transform hover:translate-x-2"
                                                                />
                                                            </a>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-center py-5 mt-3 border-t-2 bg-gradient-to-r from-customblue-1 to-customblue-2 intro-y">
                            <h2
                                style={{ fontSize: "15px" }}
                                className="bg-none text-white text-sm md:text-base lg:text-lg xl:text-lg"
                            >
                                Copyright 2021 Ⓒ All Right Reserved SSC@
                                <a
                                    href="https://www.soatsolution.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-50"
                                    // style={{ color: "#aaa" }}
                                >
                                    SO-AT
                                </a>{" "}
                                Solution.co.,ltd.@2021 ENhance Security for Web
                                Application Systems.
                            </h2>
                        </div>
                    </section>
                </footer>
            )}
        </Fragment>
    );
}

export default FooterSection;

const LinksIconBottom = ({ detail, icon, url, children }) => {
    return (
        <div className="w-full md:w-2/4 lg:w-1/4">
            {url && url ? (
                <a className="hover:no-underline" href={url} target="_self">
                    <h3 className=" mb-0 text-sm lg:text-base text-gray-800 flex my-auto">
                        <i
                            className={`${icon} fa-2x m-auto p-3  align-middle text-white
        rounded-full  bg-orange-300`}
                        ></i>
                        <span className="my-auto"> &nbsp;{detail}</span>
                    </h3>
                </a>
            ) : (
                <div>
                    <h3 className=" mb-0 text-sm lg:text-base text-gray-800 flex my-auto">
                        <i
                            className={`${icon} fa-2x m-auto p-3  align-middle text-white
        rounded-full  bg-orange-300`}
                        ></i>
                        <span className="my-auto"> &nbsp;{children} </span>
                    </h3>
                </div>
            )}
        </div>
    );
};
