
import { feedData } from './feedData.reducer';
import { feedManu } from './feedManu.reducer';
import { feedFooter} from './feedFooter.reducer'

export const infoReducers = {
    feedData,
    feedManu,
    feedFooter

};













