import React, { Fragment } from "react";

function ButtonScrollToTop() {
    const ToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    return (
        <Fragment>
            <div className="fixed bottom-4 right-5">
                <div
                    onClick={ToTop}
                    className="rounded-full py-1 w-9 bg-lightBlue-300 shadow-md text-center m-auto cursor-pointer border-2 border-lightBlue-300 hover:bg-gray-50 "
                >
                    <i className="fas fa-chevron-up fa-lg text-gray-800 hover:text-lightBlue-400"></i>
                </div>
            </div>
        </Fragment>
    );
}

export default ButtonScrollToTop;
