import React, { Fragment } from "react";
function TextContentHeader({ text,className }) {
    return (
        // bg-custompink-300
        <Fragment>
            <h2
                className={`
              mb-0 py-1 text-sm md:text-lg lg:text-lg xl:text-2xl text-orange-700 font-medium intro-y
             ${className && className} `}
            >
                &nbsp;
             {text}
            </h2>
        </Fragment>
    );
}

export default TextContentHeader;
