import {
    HeaderNavConstants
} from '../constants';

const initialState = {
    collapsed: false,
    opened: false,

};

export function headerNav(state = initialState, action) {
    const {
        type
        ,
        payload
    } = action;

    switch (type) {
        case HeaderNavConstants.ON_COLLAPSED:
            // console.log(payload)
            return {
                collapsed:!payload,
                opened:true
            };
        case HeaderNavConstants.ON_CLOSE:
            return {
                collapsed:false,
                opened:false
            };
        case HeaderNavConstants.ON_OPEN:
            return {
                collapsed:false,
                opened:!payload
            };
        default:
            return state
    }
}
