import React, { useEffect, Fragment, useRef, useState } from "react";
import { Link, Route, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { feedmanuActions } from "../actions";

import { createMarkup } from "../../helpers";
// import Search from "../Sections/Search"
// import { Accordion } from "react-bootstrap";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";

import { Disclosure, Menu, Transition, Popover } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import SubMenu from "./SubMenu";
import SmartSearch from "../components/SmartSearch/SmartSearch";
import { feedData } from "../reducers/feedData.reducer";

const Header = () => {
    // const authentication = useSelector(state => state.authentication);
    const feedManu = useSelector((state) => state.feedManu);
    let history = useHistory();
    const { register, handleSubmit } = useForm();

    const [MainMenu, setMainMenu] = useState([
        {
            seq: 0,
            manu_name_eng: null,
            manu_name: "หน้าแรก",
            url: "/home",
            icon: null,
            type: "main",
            is_parent: 0,
            status: 1,
            used_in: null,
        },
    ]);

    /****************เกี่ยวกับเมนู Desktop******************** */
    // สถานะการเปิด Sub Menu
    const [SubMenuActive, setSubMenuActive] = useState(false);
    // รายการของ Submenu
    const [ListSubMenu, setListSubMenu] = useState();
    // การปิด ถ้าปิดอยู่คือ true
    const [isCollapse, setisCollapse] = useState(true);
    // Key Save ว่าเราอยู่เมนูอะไร
    const [ActiveKeySubmenu, setActiveKeySubmenu] = useState();

    /**************** End. เกี่ยวกับเมนู Desktop******************** */
    usePageViews();

    const onSubmit = async (data, e) => {
        e.preventDefault();

        await setTimeout(
            history.push("/list/search", { search: data.search }),
            100
        );

        e.target.reset();
    };

    $(function () {
        // if ($.isFunction($.fn.smartmenus)) {
        //     $("#main-menu").smartmenus({
        //         subMenusSubOffsetX: 1,
        //         subMenusSubOffsetY: -8
        //     });
        //     $("#main-menu-mobile").smartmenus({
        //         subMenusSubOffsetX: 1,
        //         subMenusSubOffsetY: -8
        //     });
        // }
    });

    useEffect(() => {
        if (
            feedManu.fetchSuccess &&
            feedManu.data.list_manu &&
            feedManu.data.list_manu[0]
        ) {
            setMainMenu((state) => {
                return state.concat(feedManu.data.list_manu);
            });
        }
        return () => {};
    }, [feedManu.fetchSuccess]);
    // event stiky menu fixed
    useEffect(() => {
        // const el = document.querySelector(".nav-menu-bottom");
        // const observer = new IntersectionObserver(
        //     ([e]) =>
        //         e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
        //     { threshold: [1] }
        // );
        // observer.observe(el);
    }, []);
    // -- Scroll
    const navigation = [
        { name: "Dashboard", href: "#", current: true },
        { name: "Team", href: "#", current: false },
        { name: "Projects", href: "#", current: false },
        { name: "Calendar", href: "#", current: false },
    ];

    // ============ คำขวัญ ===============================
    const marqueeRef = useRef(null);
    const [marqueewidth, setmarqueeWidth] = useState(0);
    useEffect(() => {
        if (marqueeRef && marqueeRef.current) {
            setmarqueeWidth(
                marqueeRef.current.offsetWidth - marqueeRef.current.offsetLeft
            );
        }
    }, [marqueeRef.current]);
    const Maquree = ({ title, text, width }) => {
        const [pos, setPos] = useState(0);
        const [run, setRun] = useState(true);
        const scrollEff = () => {
            if (run) setPos((p) => (p < width ? p + 1 : -width));
        };

        useEffect(() => {
            const tm = setTimeout(scrollEff, 10);
            return () => clearTimeout(tm);
        }, [pos]);

        const onMouseEnter = (e) => {
            setRun(true);
        };

        const onMouseLeave = (e) => {
            setRun(true);
            setPos(pos + 1); // to trigger useEffect
        };

        const styles = {
            position: "relative",
            fontWeight: "normal",
            right: pos + "px",
            WebkitTextStroke: "",
        };

        return (
            <h2
                className="text-white font-light xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl"
                style={styles}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                dangerouslySetInnerHTML={createMarkup(`${text}`)}
            ></h2>
        );
    };
    const CoopComment =
        "ยินดีต้อนรับ สหกรณ์ออมทรัพย์พนักงานและลูกจ้างประจำองค์การสงเคราะห์ทหารผ่านศึก จำกัด";
    // ============ / คำขวัญ ===============================
    // ====================================================

    return (
        <Fragment>
            <div
                className="bg-gradient-to-r
                                            from-customblue-1 to-customblue-2 border-b-2 border-white"
            >
                {/* <div className="container xl:max-w-7xl h-2"></div> */}
                <div className="container xl:max-w-full h-10">
                    <div className="flex col-span-12 lg:col-span-10 lg:col-start-2 p-2">
                        <div
                            className=" max-w-screen-2xl hidden md:flex justify-end"
                            ref={marqueeRef}
                        >
                            {/* <h2 className="text-gray-800 border rounded-md p-2 font-bold text-xs lg:text-lg xl:text-2xl text-center md:text-center xl:text-right">
                                {CoopComment}
                            </h2> */}
                            <Maquree text={CoopComment} width={marqueewidth} />
                        </div>
                        {/* <h2 className="text-white font-light xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-xl">
                            ยินดีต้อนรับ
                            สหกรณ์ออมทรัพย์พนักงานและลูกจ้างประจำองค์การสงเคราะห์ทหารผ่านศึก
                            จำกัด
                        </h2> */}
                    </div>
                </div>
            </div>
            <div
                className="bg-blue-500"
                style={{
                    backgroundImage:
                        "url(/mediafiles/images/Utils/content_default/def_bg_con.jpg)",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="container flex xl:max-w-full justify-center">
                    <div className="hidden lg:grid p-3 mx-3">
                        <div className="flex col-span-12 lg:col-span-10 p-2">
                            <img
                                src="/mediafiles/images/Utils/cooplogo.png"
                                alt={window.config.coopNameTH}
                                className="h-48 xl:h-48 w-48 xl:w-48 ratio-1-1 rounded-full shadow-lg text-header-coopname"
                            />
                            <div className="flex-row justify-end my-auto mx-2 px-2 mt-12">
                                <h1 className="font-bold text-xs lg:text-3xl  xl:text-3xl text-white drop-shadow-lg text-shadow-lg">
                                    {window.config.coopNameTH}
                                </h1>
                                <h1 className="font-semibold text-xs lg:text-lg xl:text-lg max-w-5xl text-green-400 tracking-tighter text-shadow-lg">
                                    {window.config.coopNameEN}
                                </h1>
                                {/* Smart Search  */}
                                <div
                                    className="
                                "
                                >
                                    <SmartSearch />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div ref={marqueeRef} className="w-4/6 border rounded-md p-2">
                    <Maquree text={CoopComment}
                        width={marqueewidth} />
                </div> */}
            </div>
            <Disclosure
                as="nav"
                className="bg-gradient-to-r
                                            from-customblue-1 to-customblue-2 border-b-2 border-t-2 border-white"
            >
                {({ open }) => (
                    <>
                        <div className="max-w-screen-2xl mx-auto px-2 sm:px-6 lg:px-8">
                            <div className="relative flex items-center justify-center py-4 m-auto lg:h-14 xl:h-auto">
                                {/* Mobile menu button*/}
                                <div className="absolute inset-y-0 left-0 flex items-center xl:hidden lg:py-2">
                                    <Disclosure.Button
                                        as="button"
                                        type="button"
                                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400
                                    hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    >
                                        <span className="sr-only">
                                            Open main menu
                                        </span>
                                        {open ? (
                                            <XIcon
                                                className="block h-8 w-8 text-white"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <MenuIcon
                                                className="block h-8 w-8 text-white border-2  border-white"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                {/* Zone Mobile */}
                                <div className="flex-1 flex items-center justify-center sm:items-stretch  lg:hidden">
                                    <div className="flex-shrink-0 flex items-center">
                                        <img
                                            className="block sm:hidden h-14 w-14 rounded-full "
                                            src="/mediafiles/images/Utils/cooplogo.png"
                                            alt={window.config.coopNameTH}
                                        />
                                        <div className=" flex-auto p-2 hidden md:flex lg:hidden">
                                            <img
                                                src="/mediafiles/images/Utils/cooplogo.png"
                                                alt={window.config.coopNameTH}
                                                className="h-12  w-12 rounded-full "
                                            />
                                            <div className="my-auto mx-2">
                                                <h1 className="font-semibold text-lg  xl:text-3xl text-white">
                                                    {window.config.coopNameTH}
                                                </h1>
                                                <h2 className="font-medium text-xs xl:text-base  text-green-400 tracking-tighter">
                                                    {window.config.coopNameEN}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Zone Mobile */}
                                {/*  ของ Desktop View */}
                                <div className="hidden xl:block text-2xl sm:ml-6">
                                    <div className="block">
                                        {/* <MenuLink
                                                to={`/home`}
                                                topic={`หน้าแรก`}
                                                className=""
                                            /> */}
                                        {/* ปิดไว้ก่อน */}
                                        {/* divide-x-2 divide-orange-600 */}
                                        <ul className="flex divide-x-2 divide-black">
                                            {feedManu.fetchSuccess &&
                                                feedManu.data.list_manu &&
                                                feedManu.data.list_manu[0] &&
                                                MainMenu.map((val, i) => {
                                                    if (val.type == "main") {
                                                        if (
                                                            val.is_parent == "0"
                                                        ) {
                                                            return (
                                                                <MenuLink
                                                                    key={i}
                                                                    to={val.url}
                                                                    topic={
                                                                        val.manu_name
                                                                    }
                                                                    className=""
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <>
                                                                    <SubMenu
                                                                        val={
                                                                            val
                                                                        }
                                                                        key={i}
                                                                        c_key={
                                                                            i
                                                                        }
                                                                        to={
                                                                            val.url
                                                                        }
                                                                        topic={
                                                                            val.manu_name
                                                                        }
                                                                        list_sub_manu={feedManu.data.list_sub_manu.filter(
                                                                            (
                                                                                sub
                                                                            ) =>
                                                                                sub.manu_id ==
                                                                                val.seq
                                                                        )}
                                                                        setisCollapse={
                                                                            setisCollapse
                                                                        }
                                                                        isCollapse={
                                                                            isCollapse
                                                                        }
                                                                        setActiveKeySubmenu={
                                                                            setActiveKeySubmenu
                                                                        }
                                                                        ActiveKeySubmenu={
                                                                            ActiveKeySubmenu
                                                                        }
                                                                        setListSubMenu={
                                                                            setListSubMenu
                                                                        }
                                                                        className=""
                                                                    />
                                                                </>
                                                            );
                                                        }
                                                    }
                                                })}
                                        </ul>
                                    </div>
                                    {/*----------------เปิดใช้เพื่อ ให้  bg สีขาวยังคงอยู่ตอนเปิด Sub Menu --------------- */}
                                    <div
                                        className={`sub-menu-container py-4
                                        transition
                                        duration-500
                                        ease-linear
                                        transform
                                        ${
                                            isCollapse == false
                                                ? "opacity-100 block"
                                                : "opacity-0 hidden"
                                        }`}
                                    >
                                        <ul>
                                            {ListSubMenu &&
                                                ListSubMenu &&
                                                ListSubMenu[0] &&
                                                ListSubMenu.map((valsub, c) => {
                                                    return (
                                                        <>
                                                            <li
                                                                key={c}
                                                                className="py-1"
                                                            >
                                                                &nbsp;
                                                            </li>
                                                        </>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                    {/* -------------------------------------------------- */}
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* ของ Mobile View */}
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel className="xl:hidden">
                                <div className="px-2 pt-2 pb-3 space-y-1 block">
                                    {feedManu.fetchSuccess &&
                                        feedManu.data.list_manu &&
                                        feedManu.data.list_manu[0] &&
                                        MainMenu.map((val, i) => {
                                            if (val.type == "main") {
                                                if (val.is_parent == "0") {
                                                    return (
                                                        <MobileMenuLink
                                                            key={i}
                                                            to={val.url}
                                                            topic={
                                                                val.manu_name
                                                            }
                                                            className=""
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <MobileMenuLinkMulti
                                                            val={val}
                                                            key={i}
                                                            c_key={`submenu${i}`}
                                                            to={val.url}
                                                            topic={
                                                                val.manu_name
                                                            }
                                                            list_sub_manu={feedManu.data.list_sub_manu.filter(
                                                                (sub) =>
                                                                    sub.manu_id ==
                                                                    val.seq
                                                            )}
                                                            className=""
                                                        />
                                                    );
                                                }
                                            }
                                        })}
                                </div>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </Fragment>
    );
};

// Mobile
const MobileMenuLink = ({ to, topic, className }) => {
    return (
        <a
            href={to}
            className={classNames(
                ` text-white  bg-gradient-to-r
                                            from-customblue-1 to-customblue-2 hover:text-black  mx-2 my-1 px-2 py-2
                text-xl
                font-normal cursor-pointer no-underline block

                `,
                className
            )}
        >
            {topic}
        </a>
    );
};

const MobileMenuLinkMulti = ({
    to,
    topic,
    className,
    list_sub_manu,
    val,
    key,
    c_key,
}) => {
    return (
        <Disclosure
            as="nav"
            className="text-white hover:text-black  mx-2 px-2 py-2
            text-xl bg-gradient-to-r
                                            from-customblue-1 to-customblue-2
        font-normal flex cursor-pointer no-underline group z-20"
        >
            {({ open }) => (
                <Fragment>
                    {topic}&nbsp;
                    <Disclosure.Button
                        as="button"
                        type="button"
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400
                    hover:text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                        <span className="sr-only"></span>
                        {open ? (
                            <i className="fas fa-sort-up text-white group-hover:text-black   fa-xl"></i>
                        ) : (
                            <i className="fas fa-sort-down text-white group-hover:text-black   fa-xl"></i>
                        )}
                    </Disclosure.Button>
                    <Disclosure.Panel
                        className="absolute inset-0 bg-gradient-to-r
                                            from-customblue-1 to-customblue-2 p-2"
                    >
                        <Disclosure.Button
                            as="button"
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400
                    hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        >
                            <span className="sr-only"></span>
                            {open ? (
                                <XIcon
                                    className="block h-8 w-8 text-white"
                                    aria-hidden="true"
                                />
                            ) : (
                                <MenuIcon
                                    className="block h-8 w-8 text-white"
                                    aria-hidden="true"
                                />
                            )}
                        </Disclosure.Button>
                        <div className="p-3 space-y-1 block">
                            {list_sub_manu.length > 0 ? (
                                list_sub_manu.map((sub_m, i_m) => (
                                    <MobileMenuLinkSub
                                        to={sub_m.url}
                                        topic={sub_m.description}
                                        key={i_m}
                                    />
                                ))
                            ) : (
                                <span className="text-white font-light text-base p-3">
                                    ยังไม่พบเมนู
                                </span>
                            )}
                        </div>
                    </Disclosure.Panel>
                </Fragment>
            )}
        </Disclosure>
    );
};
// Mobile
const MobileMenuLinkSub = ({ to, topic, className }) => {
    return (
        <a
            href={to}
            className={classNames(
                ` block text-white bg-gradient-to-r
                                            from-customblue-1 to-customblue-2 hover:text-black  mx-2 px-2 py-2
               text-xl
                font-light cursor-pointer no-underline
                z-20
                `,
                className
            )}
        >
            {topic}
        </a>
    );
};
// ------------------------------------------------------------------------

// Desktop
const MenuLink = ({ to, topic, className }) => {
    return (
        <li>
            <a
                href={to}
                className={classNames(
                    ` text-white  hover:text-black  mx-3 p-1  items-center
                text-2xl md:text-2xl lg:text-2xl xl:text-2xl
                font-light cursor-pointer no-underline flex

                `,
                    className
                )}
            >
                {topic}
            </a>
        </li>
    );
};

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function usePageViews() {
    let location = useLocation();
    const dispatch = useDispatch();
    const feedManu = useSelector((state) => state.feedManu);

    useEffect(() => {
        async function feedData() {
            await dispatch(feedmanuActions.feedData(`/api/info/manu`));
        }
        if (feedManu.fetchSuccess == false) {
            feedData();
        }

        $(".rsnp-mnu").removeClass("slidein");

        //   ga.send(["pageview", location.pathname]);
    }, [location]);
}

export default Header;
