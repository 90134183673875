import { SideBarConstants } from "../constants";

function ChangeMenuKey(data) {
    return dispatch => {
        dispatch(onChangeMenukey(data));
    };
}

function ChangeTheme(data) {
    return dispatch => {
        dispatch(onChangeTheme(data));
    };
}

const onChangeMenukey = data => {
    return {
        type: SideBarConstants.ON_CHANGE_MENUKEY,
        payload:data
    };
};

const onChangeTheme = data => {
    return {
        type: SideBarConstants.ON_CHANGE_THEME,
        payload: data
    };
};

export const SideBarAction = {
    ChangeMenuKey,
    ChangeTheme
};
