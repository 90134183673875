import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import store from "./store";
import Routes from "./routes";
import nprogress from "nprogress";
// import "../../sass/conifg.scss";
// import { Helmet } from "react-helmet";

// import AOS from "aos";
import { CookiesProvider, useCookies } from "react-cookie";
// import { useLocation } from "react-router-dom";
// React Notification
import "react-notifications/lib/notifications.css";
import AcceptCookie from "./Info/components/AcceptCookie";

// import "bootstrap/dist/css/bootstrap.min.css";

//
Sentry.init({
    // dsn: process.env.SENTRY_LARAVEL_DSN,
    dsn: window.config.SENTRY_LARAVEL_DSN,
    release: "sahakornexcise@1.0.1",
});
// import "aos/dist/aos.css";

const App = () => {
    // let location = useLocation();

    // console.log('env',window.config.SENTRY_LARAVEL_DSN,process.env)
    useEffect(() => {
        // AOS.init();
        if (!("Notification" in window)) {
            // alert("Web Notification is not supported");
            // console.log("Web Notification is not supported");
            console.log("Browser does not support notifications.");
            // return;
        } else {
            if (Notification.permission === "granted") {
                // show notification here
                Notify();
            } else {
                // request permission from user
                Notification.requestPermission()
                    .then(function (p) {
                        if (p === "granted") {
                            // show notification here
                            Notify();
                        } else {
                            console.log("User blocked notifications.");
                        }
                    })
                    .catch(function (err) {
                        console.error(err);
                    });
            }
        }
        function Notify() {
            var channel = Echo.channel("nswtsco");
            channel.listen(".desktopNotify", function (data) {
                // alert(JSON.stringify(data));
                Notification.requestPermission((permission) => {
                    let notification = new Notification(data.title, {
                        body: data.body, // content for the alert
                        icon: "/mediafiles/picture/Utils/cooplogo.png", // optional image url
                    });

                    // link to page on clicking the notification
                    notification.onclick = () => {
                        window.open("show/" + data.url);
                    };
                });
            });
        }
    }, []);

    useEffect(() => {
        $(document).on("page:fetch", function () {
            nprogress.start();
        });
        $(document).on("page:change", function () {
            nprogress.done();
        });
        $(document).on("page:restore", function () {
            nprogress.remove();
        });
        // nprogress.start();
        return () => {
            // nprogress.done();
        };
    }, []);

    const [cookies, setCookie, removeCookie] = useCookies([
        "popup",
        "acceptCookie",
    ]);

    return (
        <Provider store={store}>
            <CookiesProvider>
                <Routes />
                {(!cookies.acceptCookie || cookies.acceptCookie == "N") && (
                    <AcceptCookie />
                )}
            </CookiesProvider>
        </Provider>
    );
};

if (document.getElementById("app")) {
    ReactDOM.render(<App />, document.getElementById("app"));
}
