import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route } from "react-router-dom";
import { userActions, SideBarAction, HeaderNavAction } from "../actions";
// import { history } from "../../helpers";
import SvgNew from "./SvgNew";
const Sidebar = () => {
    const dispatch = useDispatch();
    const {
        memchangepass,
        memcoll,
        memdep,
        memdiv,
        memgain,
        memkep,
        memloan,
        memshare,
        memstatus,
        memlogout,
        memWelfare,
    } = SvgNew;
    const HeaderNav = useSelector((state) => state.headerNav);
    const SideBarState = useSelector((state) => state.SideBar);

    const ListMenuMember = [
        {
            link: "/member/status",
            id: "1",
            key: "1",
            name: "ข้อมูลส่วนบุคคล",
            icon: memstatus,
        },
        {
            link: "/member/share",
            id: "2",
            key: "2",
            name: "ทุนเรือนหุ้น",
            icon: memshare,
        },
        {
            link: "/member/loan",
            id: "3",
            key: "3",
            name: "เงินกู้",
            icon: memloan,
        },
        {
            link: "/member/deposit",
            id: "4",
            key: "4",
            name: "เงินฝาก",
            icon: memdep,
        },
        {
            link: "/member/kep",
            id: "5",
            key: "5",
            name: "รายการเรียกเก็บ",
            icon: memkep,
        },
        {
            link: "/member/coll",
            id: "6",
            key: "6",
            name: "ค้ำประกัน",
            icon: memcoll,
        },
        {
            link: "/member/dividend",
            id: "8",
            key: "8",
            name: "ปันผลเฉลี่ยคืน",
            icon: memdiv,
        },
        {
            link: "/member/gian",
            id: "7",
            key: "7",
            name: "ผู้รับผลประโยชน์",
            icon: memgain,
        },

        {
            link: "/member/password",
            id: "9",
            key: "9",
            name: "เปลี่ยนรหัสผ่าน",
            icon: memchangepass,
        },
    ];
    return (
        <Fragment>
            {/*  <!-- Desktop sidebar --> */}
            <aside className="pt-12 z-20 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 lg:block flex-shrink-0 fixed h-screen">
                {/* <div className="border-b-4 p-2 border-blue-300 m-auto flex w-4/6" /> */}

                <ul className="mt-3">
                    {ListMenuMember.map((val, i) => {
                        return (
                            <LiTailwindcss
                                to={val.link}
                                // icon={`M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z`}
                                icon={val.icon}
                                name={val.name}
                                title={""}
                                id={val.id}
                                key={i + 1}
                                c_key={SideBarState.key}
                            />
                        );
                    })}
                    <li className="-intro-x relative px-3 py-2 cursor-pointer hover:bg-lightBlue-200    transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                        <div
                            onClick={() => dispatch(userActions.logout())}
                            className="inline-flex items-center w-full
            text-gray-800
            text-sm font-semibold transition-colors duration-150
             hover:text-gray-800 dark:hover:text-gray-200

            "
                        >
                            {/* {icon} */}
                            <div
                                className={`p-1 rounded-full bg-gradient-to-tr
                     transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110
                    group-hover:from-gray-200 group-hover:via-custom-50 group-hover:to-custom-100
                    from-custom-200 via-custom-100  to-custom-400
                      shadow-md`}
                            >
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    className="w-7 h-7 "
                                    viewBox="0 0 100 100"
                                    style={{
                                        enableBackground: "new 0 0 100 100",
                                    }}
                                    xmlSpace="preserve"
                                    dangerouslySetInnerHTML={{
                                        __html: memlogout,
                                    }}
                                ></svg>
                            </div>
                            <h2 className="ml-4 mb-0 text-base">ออกจากระบบ</h2>
                        </div>
                    </li>
                </ul>
                <div className="border-b-4 p-2 border-blue-300 m-auto flex w-4/6" />

                <div
                    className="-intro-x inline-flex items-center w-full mt-2
                    px-1
            text-gray-800
            text-sm font-semibold transition-colors duration-150
             hover:text-gray-800
            "
                >
                    {/* <a href={`./home`}>

                        <h5 className="ml-4 text-gray-800 text-sm lg:text-base">
                            <i className="fas fa-reply fa-lg"></i>&nbsp;
                            กลับหน้าหลัก
                        </h5>
                    </a> */}
                    <a href={`/home`}>
                        <h5 className="ml-4 text-gray-800 text-sm lg:text-base">
                            <i className="fas fa-reply fa-lg"></i>&nbsp;
                            กลับหน้าหลัก
                        </h5>
                    </a>
                </div>
            </aside>
            {HeaderNav.opened && (
                <Fragment>
                    <div
                        // x-show="isSideMenuOpen"

                        // x-transition:enter="transition ease-in-out duration-150"
                        // x-transition:enter-start="opacity-0"
                        // x-transition:enter-end="opacity-100"
                        // x-transition:leave="transition ease-in-out duration-150"
                        // x-transition:leave-start="opacity-100"
                        // x-transition:leave-end="opacity-0"
                        className="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
                        onClick={() => {
                            // console.log("clicked");
                            dispatch(
                                HeaderNavAction.onToggleOpened(HeaderNav.opened)
                            );
                        }}
                    ></div>
                    <div className="">
                        <aside
                            className="fixed inset-y-0 z-50 flex-shrink-0 w-64 mt-14 overflow-y-auto bg-white dark:bg-gray-800 lg:hidden"
                            // x-show="isSideMenuOpen"
                            // x-transition:enter="transition ease-in-out duration-150"
                            // x-transition:enter-start="opacity-0 transform -translate-x-20"
                            // x-transition:enter-end="opacity-100"
                            // x-transition:leave="transition ease-in-out duration-150"
                            // x-transition:leave-start="opacity-100"
                            // x-transition:leave-end="opacity-0 transform -translate-x-20"
                            // @click.away="closeSideMenu"
                            // @keydown.escape="closeSideMenu"
                            onClick={() => {
                                // console.log("clicked");
                                dispatch(
                                    HeaderNavAction.onToggleOpened(
                                        HeaderNav.opened
                                    )
                                );
                            }}
                        >
                            {/* py-4 */}
                            <div className=" text-gray-500 dark:text-gray-400">
                                <ul className="mt-3">
                                    {ListMenuMember.map((val, i) => {
                                        return (
                                            <LiTailwindcssMoblie
                                                to={val.link}
                                                // icon={`M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z`}
                                                icon={val.icon}
                                                name={val.name}
                                                title={""}
                                                id={val.id}
                                                key={i + 1}
                                                c_key={SideBarState.key}
                                            />
                                        );
                                    })}
                                    <li className="-intro-x relative px-3 py-2 cursor-pointer hover:bg-lightBlue-200    transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                                        <div
                                            onClick={() =>
                                                dispatch(userActions.logout())
                                            }
                                            className="inline-flex items-center w-full
                                        cursor-pointer
            text-gray-800
            text-sm font-semibold transition-colors duration-150
             hover:text-gray-800 dark:hover:text-gray-200

            "
                                        >
                                            <div
                                                className={`p-1 rounded-full bg-gradient-to-tr
                     transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110
                    group-hover:from-gray-200 group-hover:via-custom-50 group-hover:to-custom-100
                    from-custom-200 via-custom-100  to-custom-400
                      shadow-md`}
                                            >
                                                <svg
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    className="w-7 h-7 "
                                                    viewBox="0 0 100 100"
                                                    style={{
                                                        enableBackground:
                                                            "new 0 0 100 100",
                                                    }}
                                                    xmlSpace="preserve"
                                                    dangerouslySetInnerHTML={{
                                                        __html: memlogout,
                                                    }}
                                                ></svg>
                                            </div>
                                            <h2 className="ml-4 mb-0 text-base">
                                                ออกจากระบบ
                                            </h2>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                        {/* <div className="bg-red-200 w-full h-auto">&nbsp;</div> */}
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};
const LiTailwindcssMoblie = ({
    to,
    icon,
    name,
    title,
    id,
    key,
    c_key,
    ...rest
}) => {
    return (
        <li
            className={`-intro-x relative px-3 py-2 ${
                id == (c_key && c_key) ? "bg-custom-200 " : ""
            }
        hover:bg-custom-200
        `}
            key={id}
        >
            {id == c_key && c_key && (
                <Fragment>
                    <span
                        className="absolute inset-y-0 left-0 w-1 bg-custom-600 rounded-tr-lg rounded-br-lg"
                        aria-hidden="true"
                    ></span>
                </Fragment>
            )}

            <Link
                className="inline-flex text-gray-800 items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                to={`${to}`}
            >
                <div
                    className={`p-1 rounded-full bg-gradient-to-tr
                 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110
                group-hover:from-gray-200 group-hover:via-custom-50 group-hover:to-custom-100
                ${
                    id == (c_key && c_key)
                        ? "from-gray-200 via-custom-50 to-custom-100"
                        : "from-custom-200 via-custom-100  to-custom-400 "
                }
                  shadow-md`}
                >
                    <svg
                        version="1.1"
                        xmlns="https://www.w3.org/2000/svg"
                        xmlnsXlink="https://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        className="w-7 h-7 "
                        viewBox="0 0 100 100"
                        style={{ enableBackground: "new 0 0 100 100" }}
                        xmlSpace="preserve"
                        dangerouslySetInnerHTML={{ __html: icon }}
                    ></svg>
                </div>

                <h2 className="ml-4 text-base">{name}</h2>
            </Link>
        </li>
    );
};

const LiTailwindcss = ({ to, icon, name, title, id, key, c_key, ...rest }) => {
    return (
        <li
            className={`-intro-x relative px-3 py-2 group
        transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110
        hover:shadow
        ${
            id == (c_key && c_key)
                ? "bg-custom-200 underline"
                : "hover:bg-lightBlue-200"
        }

        `}
            key={id}
        >
            {id == c_key && c_key && (
                <Fragment>
                    <span
                        className="absolute inset-y-0 left-0 w-1 bg-custom-600 rounded-tr-lg rounded-br-lg"
                        aria-hidden="true"
                    ></span>
                </Fragment>
            )}
            <Link
                to={`${to}`}
                className="inline-flex items-center w-full
        text-gray-800
        text-sm font-semibold transition-colors duration-150
         hover:text-gray-800 dark:hover:text-gray-200
        "
            >
                <div
                    className={`p-1 rounded-full bg-gradient-to-tr
                 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110
                group-hover:from-gray-200 group-hover:via-custom-50 group-hover:to-custom-100
                    ${
                        id == (c_key && c_key)
                            ? "from-gray-200 via-custom-50 to-custom-100"
                            : "from-custom-200 via-custom-100  to-custom-400 "
                    }
                  shadow-md`}
                >
                    <svg
                        version="1.1"
                        xmlns="https://www.w3.org/2000/svg"
                        xmlnsXlink="https://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        className="w-7 h-7 "
                        viewBox="0 0 100 100"
                        style={{ enableBackground: "new 0 0 100 100" }}
                        xmlSpace="preserve"
                        dangerouslySetInnerHTML={{ __html: icon }}
                    ></svg>
                </div>
                <h2 className="ml-4 text-base"> {name}</h2>
            </Link>
        </li>
    );
};

export default Sidebar;
