import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { createMarkup, remarkFoundWord } from "../../../helpers";
import Services from "../../services/services";

const InSearching = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    // Serviecs
    const service = new Services();
    // สถานะว่าหาเนื้อหาเจอไหม
    const [FoundStatus, setFoundStatus] = useState();
    // เนื้อหาที่ค้นหาเจอ
    const [FoundData, setFoundData] = useState();
    // เมนูแนะนำ
    const [RecommenedData, setRecommenedData] = useState();
    //  ตัวโหลดค้นหา
    const [searchLoading, setsearchLoading] = useState(false);
    // จะบอกว่าเคยทำการค้นหาไปแล้วหรือยัง?
    const [isSearch, setisSearch] = useState(false);

    const [SearchInput, setSearchInput] = useState();

    // --------------------------------

    // --------------------------------
    const handlerSearch = (data) => {
        // console.log(data);
        // ประกาศ Body ที่จะส่งไป Services
        const Mydata = { type: "search", search: data.search };
        setSearchInput(data.search);
        //    เรียก function  Services
        GoSearching(Mydata);
        // สร้าง function Services
        async function GoSearching(MyData) {
            setisSearch(false);
            setsearchLoading(true);
            await service
                .API(`/api/info/list/smartsearch`, {
                    method: "POST",
                    data: MyData,
                })
                .then((res) => {
                    if (res.code == "CWL200SUCCESS") {
                        setsearchLoading(false);
                        setisSearch(true);
                        // console.log(res);
                        if (res.result.isSearchFound == "1") {
                            // Set ข้อมูลการค้นหา
                            setFoundData(res.result.result);
                            // Set สถานะการค้นหา
                            setFoundStatus(true);
                        } else {
                            setFoundStatus(false);
                        }
                        // Set เมนูแนะนำ
                        setRecommenedData(res.result.recommened);
                    }
                });
        }
    };
    return (
        <>
            <div className="w-full">
                <form onSubmit={handleSubmit(handlerSearch)}>
                    <button
                        type="submit"
                        className="rounded-lg bg-blue-300 text-blue-800 font-medium text-sm
                md:text-base lg:text-lg p-2 shadow hover:bg-blue-800 hover:text-white"
                    >
                        ค้นหา
                    </button>
                    {/* <label htmlFor="search">
                    <i className="fas fa-search"></i>
                    </label> */}
                    <input
                        autoFocus
                        type="text"
                        name="search"
                        ref={register({
                            required: true,
                        })}
                        className=" w-full outline-none  m-2 p-2 border-b-2 focus:border-blue-600 text-gray-700 font-medium
                    "
                        placeholder="พิมพ์ค้นหาเนื้อหาต่างๆ "
                    />
                </form>

                <div className="overflow-auto h-48 md:h-80 lg:h-96 overflow-y-auto">
                    {searchLoading && searchLoading == true && (
                        <div className="text-center m-auto justify-center flex">
                            <i className="fas fa-spinner animate-spin text-base lg:text-lg xl:text-2xl text-gray-700"></i>
                        </div>
                    )}
                    {isSearch && isSearch == true && (
                        <>
                            {/* ถ้าหากว่าไม่เจอเนื้อหาที่ค้นหา */}
                            {FoundStatus && FoundStatus == true ? (
                                <>
                                    {" "}
                                    <div className=" py-2 my-1">
                                        <div className="border-b-2 border-gray-50 my-1 text-gray-600">
                                            <label className="font-light text-sm md:text-lg lg:text-lg xl:text-lg">
                                                ผลการค้นหา&nbsp;&nbsp;
                                            </label>
                                            <span>&rdquo;</span>
                                            <label className="text-gray-800 font-medium text-sm md:text-lg lg:text-lg xl:text-lg">
                                                {SearchInput}
                                            </label>

                                            <span>&rdquo;</span>
                                        </div>
                                        <ListContent
                                            Data={FoundData && FoundData}
                                            SearchInput={
                                                SearchInput && SearchInput
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex py-2 my-2 text-gray-600 ">
                                        <label className="font-light text-sm md:text-lg lg:text-lg xl:text-lg">
                                            ไม่พบคำที่ค้นหา&nbsp;&nbsp;
                                        </label>
                                        <span>&rdquo;</span>
                                        <label className="text-gray-800 font-medium text-sm md:text-lg lg:text-lg xl:text-lg">
                                            {SearchInput}
                                        </label>
                                        <span>&rdquo;</span>
                                    </div>
                                </>
                            )}
                            <div className="my-1 border-t-2 border-gray-50">
                                <h2 className="text-gray-700 font-medium text-xs md:text-base lg:text-lg xl:text-lg">
                                    <i className="far fa-star"></i>
                                    &nbsp;เมนูแนะนำ
                                </h2>
                                <ul>
                                    {RecommenedData &&
                                        RecommenedData &&
                                        RecommenedData[0] &&
                                        RecommenedData.map((val, i) => {
                                            return (
                                                <li
                                                    key={i}
                                                    className="p-2 my-2 bg-gray-50 rounded-lg text-gray-700   text-xs md:text-base lg:text-lg xl:text-lg font-normal hover:bg-gray-200"
                                                >
                                                    <a
                                                        href={val.Note}
                                                        target="_self"
                                                        title={val.Question}
                                                    >
                                                        {val.Question}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default InSearching;

// เนื้อหาที่ค้นหาเจอ
const ListContent = ({ Data, SearchInput }) => {
    return (
        <>
            <ul className="">
                {Data &&
                    Data.map((val, i) => {
                        let QuestionRemark = remarkFoundWord(
                            val.Question,
                            SearchInput,
                            "Question"
                        );
                        let NoteRemark = remarkFoundWord(val.Note, SearchInput);

                        return (
                            <li
                                key={i}
                                className="bg-blue-50 rounded-lg hover:bg-gray-100 py-2 px-2 my-2
                                text-gray-700 text-sm md:text-base lg:text-lg xl:text-lg font-light  "
                            >
                                <a
                                    href={`/show/${val.No}`}
                                    target="_self"
                                    className="flex"
                                >
                                    <i className=" text-gray-800 fas fa-bookmark fa-1x flex"></i>
                                    &nbsp;
                                    <span
                                        className=" smart-search"
                                        dangerouslySetInnerHTML={createMarkup(
                                            QuestionRemark && QuestionRemark
                                        )}
                                    ></span>
                                </a>
                                {parseInt(val.find_note && val.find_note) >
                                    0 && (
                                    <label
                                        className="text-gray-400 smart-search text-xs md:text-xs lg:text-sm xl:text-sm"
                                        dangerouslySetInnerHTML={createMarkup(
                                            NoteRemark && NoteRemark
                                        )}
                                    ></label>
                                )}
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};
