import React from "react";
import { useEffect, Fragment } from "react";
import { useState } from "react";
import { convert_to_Thaidate, moment_datetime } from "../../../helpers";
import Services from "../../services/services";

const LastUpdateWebMember = () => {
    const service = new Services();
    const [DateUpdate, setDateUpdate] = useState();
    useEffect(() => {
        feedData();
        async function feedData() {
            await service
                .API(`/api/member/lastupdate_bydepstatement`, {
                    method: "POST",
                })
                .then((data) => setDateUpdate(data.data));
        }

        return () => {};
    }, []);

    return (
        <Fragment>
            <p className="font-light text-gray-600 text-sm md:text-base lg:text-base xl:text-lg intro-x">
                อัพเดทล่าสุดเมื่อ&nbsp;
                {DateUpdate && convert_to_Thaidate(DateUpdate)}
            </p>
        </Fragment>
    );
};

export default LastUpdateWebMember;
