import React, { useState, useEffect } from "react";

const SubMenu = ({
    to,
    topic,
    className,
    list_sub_manu,
    val,
    key,
    c_key,
    isCollapse,
    setisCollapse,
    ActiveKeySubmenu,
    setActiveKeySubmenu,
    setListSubMenu,
}) => {
    const [IconSwap, setIconSwap] = useState(false);

    useEffect(() => {
        if (c_key == ActiveKeySubmenu && isCollapse == false) {
            setIconSwap(true);
        } else if (c_key != ActiveKeySubmenu) {
            setIconSwap(false);
        }

        return () => {};
    }, [ActiveKeySubmenu]);

    const OnClickSubmenu = () => {
        if (c_key === ActiveKeySubmenu) {
            setisCollapse(true);
            setActiveKeySubmenu(null);
        } else {
            setListSubMenu(list_sub_manu);
            setisCollapse(false);
            setActiveKeySubmenu(c_key);
        }
        return;
    };

    return (
        <>
            <li
                className={`${
                    c_key === ActiveKeySubmenu ? "text-black" : "text-white"
                }   hover:text-black  p-1  mx-3 pl-3
        text-2xl md:text-2xl lg:text-2xl xl:text-2xl
        font-light cursor-pointer no-underline group `}
                onClick={OnClickSubmenu}
            >
                <label className="flex cursor-pointer">
                    {topic}
                    &nbsp;
                    <i
                        className={`fas fa-angle-${
                            IconSwap == true ? "up" : "down"
                        } items-center flex`}
                    ></i>
                </label>
                {c_key === ActiveKeySubmenu && (
                    <>
                        <ul className="absolute pt-2 z-20">
                            {list_sub_manu &&
                                list_sub_manu &&
                                list_sub_manu[0] &&
                                list_sub_manu.map((valsub, c) => {
                                    return (
                                        <li key={c}>
                                            <a
                                                href={valsub.url}
                                                className={classNames(
                                                    ` text-white  hover:text-black  mx-1 p-1  items-center
                text-xs md:text-sm lg:text-base xl:text-base
                font-medium cursor-pointer no-underline flex

                `,
                                                    className
                                                )}
                                            >
                                                {valsub.description}
                                            </a>
                                        </li>
                                    );
                                })}
                        </ul>
                    </>
                )}
            </li>
        </>
    );
};

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default SubMenu;
