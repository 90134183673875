//import libs
import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
// import LoadingOverlay from "react-loading-overlay";
import { useHistory, useLocation } from "react-router-dom";
// import components
import Header from "./Header";
import Sidebar from "./Sidebar";
import { SideBarAction } from "../actions";
import { Helmet } from "react-helmet";
import Services from "../services/services";
import WelcomeMember from "./WelcomeMember";
import { useState } from "react";

function MemberLayout({ children }) {
    let location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    // const state = useSelector(state => state);
    const HeaderNav = useSelector((state) => state.headerNav);
    const authentication = useSelector((state) => state.authentication);

    const [termofConditional, settermofConditional] = useState();
    // const SideBarState = useSelector(state => state.SideBar);
    // useEffect(() => {
    //     $("body").css("overflow-x", "unset");
    // }, []);
    const [postSuccess, setPostSuccess] = useState(false);
    const service = new Services();

    // const SideBarState = useSelector(state => state.SideBar);
    useEffect(() => {
        async function CheckFirstLoggin() {
            await service
                .API(`/api/member/termofconditional`, {
                    method: "POST",
                })
                .then((data) => {
                    setPostSuccess(true);
                    if (data.rc_code == "1") {
                        settermofConditional(true);
                        // console.log(data)
                    } else {
                        history.push("/member/welcome");
                    }
                });
        }

        CheckFirstLoggin();
    }, []);
    useEffect(() => {
        dispatch(
            SideBarAction.ChangeMenuKey({
                key: MenupathSearch(location.pathname),
            })
        );
    }, [location]);
    // ------------------------------------------
    // ------------------------------------------
    useEffect(() => {
        function setContentPadding() {
            if (window.innerWidth < 1024) {
                $("#content-warp-box").css({
                    "padding-left": 0,
                });

                $("#webmember-content-box").css("padding", 0);
                $("#side-bar-left").css("width", 0);
            } else {
                $("#content-warp-box").css({
                    "padding-left": "16rem",
                });

                $("#webmember-content-box").css("padding", "0 1.5rem");
                $("#side-bar-left").css("width", "16rem");
            }
        }

        setContentPadding();

        window.addEventListener("resize", () => {
            setContentPadding();
        });
    }, []);
    // ------------------------------------------
    // ------------------------------------------

    const goBack = () => {
        window.history.back();
    };

    let MemberpathList = [
        { name: "/member/status", value: "1" },
        { name: "/member/share", value: "2" },
        { name: "/member/loan", value: "3" },
        { name: "/member/loan_statement", value: "3" },
        { name: "/member/deposit", value: "4" },
        { name: "/member/dep_statement", value: "4" },
        { name: "/member/kep", value: "5" },
        { name: "/member/kepcrem", value: "12" },
        { name: "/member/coll", value: "6" },
        { name: "/member/gian", value: "7" },
        { name: "/member/dividend", value: "8" },
        { name: "/member/password", value: "9" },
        { name: "/member/logout", value: "10" },
        { name: "/member/welfare", value: "10" },
        { name: "/member/logon", value: "11" },
        { name: "/member/welcome", value: "99" },
    ];

    const MenupathSearch = (prop) => {
        if (prop) {
            let myprop = prop;
            let mysplit = myprop.split("/");

            let myvalue = MemberpathList.find((value, k) => {
                // console.log(`/${mysplit[1]}/${mysplit[2]}`,value.name);
                if (value.name == `/${mysplit[1]}/${mysplit[2]}`) {
                    return value;
                }
            });
            // console.log(myvalue)
            if (myvalue.value) {
                return myvalue.value.toString();
            } else {
                return "1";
            }
        }
    };
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                {/* <link rel="canonical" href="http://smskcoop.com/portal-new/" /> */}
                <meta property="og:locale" content="th_TH" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content={`main - ${window.config.coopNameTH}`}
                />
                <meta
                    property="og:description"
                    content={`ระบบตรวจสอบข้อมูลสมาชิก Online แบบ Realtime ${window.config.coopNameTH} ${window.config.www_description}`}
                />
                <meta property="og:url" content={window.location.href} />
                <link rel="canonical" href={window.location.href} />
                <meta
                    property="og:site_name"
                    content={`${window.config.coopNameTH}`}
                />
            </Helmet>
            {postSuccess == true ? (
                <Fragment>
                    <div
                        id="page-content-wrapper"
                        className={`flex h-screen bg-gray-50 dark:bg-gray-900 ${
                            HeaderNav.opened && "overflow-hidden"
                        }`}
                    >
                        <Sidebar />
                        {/* container-fluid */}

                        <div
                            className="w-full md:pl-1  lg:pl-64  overflow-auto  bg-white"
                            id="content-warp-box"
                        >
                            <Header />
                            {/* <Header /> */}
                            {/* <div className="flex pt-16 flex-wrap ">
                                <div className="flex justify-center  w-full md:w-1/6">
                                    <img
                                        src="./mediafiles/images/Utils/cooplogo.png"
                                        className="w-28 h-28 xl:w-40 xl:h-40 animate-pulse rounded-full "
                                        alt={`โลโก้ ${window.config.coopNameTH}`}
                                    />
                                </div>
                                <div className="w-full md:w-5/6 lg:pl-12">
                                    <h1 className=" text-gradient text-center lg:text-left bg-clip-text text-transparent bg-gradient-to-r from-custom-700 to-custom-500 no-m m-t-xs py-2 text-base xl:text-4xl lg:text-2xl md:text-xl font-bold  overflow-hidden">
                                        {window.config.coopNameTH}
                                    </h1>
                                    <h1 className="no-m  text-custom-600 text-center lg:text-left text-xs xl:text-lg lg:text-lg  md:text-sm font-bold  overflow-hidden">
                                        {window.config.coopNameEN}
                                    </h1>
                                    <h2 className="mb-0 text-custom-700 text-center lg:text-left font-normal text-sm md:text-base lg:text-lg xl:text-2xl">
                                        <i classname="fas fa-user-circle fa-1x text-custom-700"></i>{" "}
                                        <label classname="text-custom-700 font-light">
                                            ทะเบียน
                                        </label>
                                        {authentication &&
                                            authentication.loggedIn &&
                                            ` ${authentication.user.membership_no}  : ${authentication.user.member.member_name}  ${authentication.user.member.member_surname}`}
                                    </h2>
                                </div>
                            </div> */}
                            {/* <div className="py-1 w-4/6 mx-auto">

                    </div> */}

                            <div className="border-b-2 border-blue-300 m-1 p-3" />
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: "a:hover{cursor: pointer;}",
                                }}
                            />
                            <div className="w-28 p-2 text-left hover:underline text-base">
                                <a onClick={goBack}>
                                    <span>
                                        <i className="fas fa-fast-backward fa-2x" />
                                    </span>
                                    &nbsp;กลับ
                                </a>
                            </div>
                            <div
                                className="lg:pl-6 lg:pr-6"
                                id="webmember-content-box"
                            >
                                <h2 id="coop-content mb-0">{children}</h2>
                            </div>
                        </div>
                    </div>
                    <div id="toTop" className="btn-totop text-center">
                        <span>
                            <i
                                className="glyphicon glyphicon-chevron-up"
                                style={{
                                    right: 1,
                                    top: "-15px",
                                    position: "relative",
                                    color: "white",
                                }}
                            />
                        </span>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <WelcomeMember />
                </Fragment>
            )}
        </Fragment>
    );
}

const displayName = "Member Layout";
const propTypes = {
    children: PropTypes.node.isRequired,
};

MemberLayout.dispatch = displayName;
MemberLayout.propTypes = propTypes;

export default MemberLayout;
