import { MemWelfareConstant } from '../constants';
import { feedmemdataService } from '../services';
import { NotificationManager } from 'react-notifications';

function feedDataGet(url) {
    return (dispatch) => {
        dispatch(fetching(true));

        feedmemdataService.feedDataGet(url)
            .then(
                data => dispatch(fetchSuccess(data)),
                err => dispatch(fetchFailure(true))
            );
    }
}

function feedDataPost(url, body = {}) {
    return (dispatch) => {
        dispatch(fetching(true));

        feedmemdataService.feedDataPost(url, body)
            .then(
                data => dispatch(fetchSuccess(data)),
                err => dispatch(fetchFailure(true))
            );
    }
}

function tabsChanges(data) {
    return (dispatch) => {
       dispatch(TabsChangeAction(data));

        // feedmemdataService.feedDataPost(url, body)
        //     .then(
        //         data => dispatch(fetchSuccess(data)),
        //         err => dispatch(fetchFailure(true))
        //     );
    }
}


const fetching = (bool) => {
    return {
        type: MemWelfareConstant.FEED_REQUEST,
        fetching: bool
    }
};

const fetchSuccess = (data) => {
    return {
        type: MemWelfareConstant.FEED_SUCCESS,
        payload: data
    }
}

const TabsChangeAction = (data) => {
    return {
        type: MemWelfareConstant.TABS_CHANGES,
        payload: data
    }
}

const fetchFailure = (bool) => {
    NotificationManager.error('ไม่สามารถเชื่อมต่อได้', 'Error', 5000);
    return {
        type: MemWelfareConstant.FEED_FAILURE
    }
}

export const feedMemWelfareActions = {
    feedDataGet,
    feedDataPost,
    tabsChanges
    // feedHomepage,
}
