import { lazy } from "react";

const MemberRoute = [
    {
        path: "/member/logon",
        exact: true,
        component: lazy(() => import("../pages/MemLogin/Memlogin"))
    },
    {
        path: "/member/registerauth",
        exact: true,
        component: lazy(() => import("../pages/Memregis/UserAuthMain"))
    },
    {
        path: "/member/termofconditional",
        exact: true,
        // component: lazy(() => import("../pages/Memregis/Memregis"))
        component: lazy(() => import("../pages/Memregis/termOfConditional"))
    },
    {
        path: "/member/register",
        exact: true,
        // component: lazy(() => import("../pages/Memregis/Memregis"))
        component: lazy(() => import("../pages/Memregis/UserAuthMain"))
    },
    {
        path: "/member/forget",
        exact: true,
        // component: lazy(() => import("../pages/Memregis/Memregis"))
        component: lazy(() => import("../pages/Memregis/UserAuthMain"))
    },
    {
        path: "/member/impersonate",
        exact: true,
        component: lazy(() => import("../pages/MemImpersonate/MemInpersonate"))
    },
    {
        path: "/member/status",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemStatus/Memstatus"))
    },
    {
        path: "/member/share",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemShare/MemShare"))
    },
    {
        path: "/member/loan",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemLoan/MemLoan"))
    },
    {
        path: "/member/loan_statement/:statement",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemLoan/MemLoanStatement"))
    },
    {
        path: "/member/deposit",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/Memdep/Memdep"))
    },
    {
        path: "/member/dep_statement/:statement",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/Memdep/MemdepStatement"))
    },
    {
        path: "/member/kep",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/Memkep/Memkep"))
    },
    {
        path: "/member/kepcrem",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemkepCrem/MemkepCrem"))
    },
    {
        path: "/member/coll",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemColl/MemColl"))
    },
    {
        path: "/member/gian",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemGian/Memgian"))
    },
    {
        path: "/member/welfare",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemWelfare/MemWelfare"))
    },
    {
        path: "/member/dividend",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemDiv/Memdiv"))
    },
    {
        path: "/member/password",
        exact: true,
        auth: true,
        layout: "member",
        component: lazy(() => import("../pages/MemPass/Mempass"))
    },
    {
        path: "/member/welcome",
        exact: true,
        auth: true,
        component: lazy(() => import("../pages/MemWelcome/Welcome"))
    },
    // {
    //     path: "/member/test",
    //     exact: true,
    //     auth: false,
    //     component: lazy(() => import("../pages/Dialog/DialogOtpForm"))
    // }
];

export default MemberRoute;
