// import libs
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

// import ScrollToTop from "../helpers/ScrollToTop";

// import components
import routes from "./routes";
import PublicRoute from "./Public";
import PrivateRoute from "./Private";

import Layout from "./layout";
import NoMatch from "../Member/pages/Error/NoMatch";
import ErrorBoundary from "../Member/pages/Error/ErrorBoundary";

function Routes() {
    return (
        <Router>
            <ErrorBoundary>
                <Layout>
                    {/* <ScrollToTop /> */}
                    <Switch>
                        {routes.map((route, i) => {
                            if (route.auth) {
                                return <PrivateRoute key={i} {...route} />;
                            }
                            return <PublicRoute key={i} {...route} />;
                        })}
                        <Route path="*">
                            {/* <Redirect to="/home" /> */}
                            <NoMatch />
                        </Route>
                    </Switch>
                </Layout>
                <NotificationContainer />
            </ErrorBoundary>
        </Router>
    );
}

// function NoMatch() {
//     let location = useLocation();

//     return (
//         <div>
//             <h3>
//                 No match for <code>{location.pathname}</code>
//             </h3>
//         </div>
//     );
// }

export default Routes;
