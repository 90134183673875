import {
    SideBarConstants
} from '../constants';

const initialState = {
    key: "1",
    theme: 'light',

};

export function SideBar(state = initialState, action) {
    const {
        type
        ,
        payload
    } = action;

    switch (type) {
        case SideBarConstants.ON_CHANGE_MENUKEY:
            // console.log(payload)
            return {
                key:payload.key,
                theme:state.theme
            };
        case SideBarConstants.ON_CHANGE_THEME:
            return {
                key:payload.key,
                theme:payload.theme
            };
        default:
            return state
    }
}
