import React, { Fragment } from "react";

function NoMatch() {
    return (
        <Fragment>
            <div className="text-center m-2 bg-white">
                <h1 className="m-auto text-center">
                    <img
                        src="./mediafiles/images/Utils/cooplogo.png"
                        itemProp="image"
                        alt={`โลโก้${window.config.coopNameTH}`}
                        className="w-40 md:w-56 lg:w-60  rounded-full shadow-sm  m-auto "
                    />

                    <div className="  pl-2 pt-2 text-center py-2">
                        <h2 className="text-sm md:text-2xl  text-gray-400 lg:text-4xl xl:text-3xl font-bold">
                            {window.config.coopNameTH}
                        </h2>
                        <h2 className="text-xs md:text-lg   text-gray-400 lg:text-2xl  xl:text-lg font-bold">
                            {window.config.coopNameEN}
                        </h2>
                    </div>
                </h1>

                <i className="fas fa-exclamation-circle fa-6x text-red-500 shadow-sm rounded-full"></i>
                <h1 className="text-base md:text-xl lg:text-2xl mt-1 text-rose-500 font-semibold">
                    ขออภัย!ไม่พบหน้าเว็บไซต์ที่ค้นหา...
                </h1>

                <a href={`/home`}>
                    <h1 className="text-sm lg:text-base text-gray-700 font-medium w-36 flex text-center m-auto ">
                        <i className="fas fa-home fa-md"></i>&nbsp; กลับหน้าหลัก
                    </h1>
                </a>
            </div>
        </Fragment>
    );
}

export default NoMatch;
