import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
// import ScrollToTop from "../helpers/ScrollToTop";
// import LoadingOverlay from "react-loading-overlay";

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (
                    <Suspense
                    fallback={<> </>}
                        // fallback={
                        //     // <LoadingOverlay
                        //     //     active={false}
                        //     //     spinner
                        //     //     text="Loading ..."
                        //     // >
                        //         <p
                        //             style={{
                        //                 width: "1024px",
                        //                 height: "1024px"
                        //             }}
                        //         ></p>
                        //     // </LoadingOverlay>
                        // }
                    >
                    {/* <ScrollToTop /> */}
                        <Component {...props} />
                    </Suspense>
                );
            }}
        />
    );
};

PublicRoute.propTypes = {
    component: PropTypes.object.isRequired,
    location: PropTypes.object
};

export default PublicRoute;
