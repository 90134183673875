import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { Link, useHistory } from "react-router-dom";
import Services from "../services/services";
import { useDispatch } from "react-redux";
import { userActions } from "../actions";
function WelcomeMember() {
    const history = useHistory();
    const service = new Services();
    const dispatch = useDispatch();
    useEffect(() => {
        //
        member_auth();

        async function member_auth() {
            await service
                .API(`/api/member/auth/getAuthUser`, {
                    method: "POST",
                })
                .then((data) => {
                    // console.log(data);
                    if (data.rc_code == "1") {
                        // console.log(data);
                    } else {
                        dispatch(userActions.logout());
                        alert(
                            "ขออภัยสิ้นสุดระยะเวลาการเข้าสู่ระบบ กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
                        );
                        history.push("/member/logon");
                    }
                })
                .catch((err) => {
                    if (err) {
                        localStorage.removeItem("user");
                        alert(
                            "ขออภัยสิ้นสุดระยะเวลาการเข้าสู่ระบบ กรุณาเข้าสู่ระบบใหม่อีกครั้ง catch"
                        );
                        // localStorage.removeItem("user");
                        dispatch(userActions.logout());
                        history.push("/member/logon");
                    }
                });
        }
    }, []);
    return (
        <Fragment>
            <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                    <img
                        src="./mediafiles/images/Utils/cooplogo.png"
                        className="w-28 h-28 xl:w-40 xl:h-40 animate-pulse rounded-full  mx-auto"
                        alt={`โลโก้ ${window.config.coopNameTH}`}
                    />
                    <h1
                        className="text-sm md:text-lg lg:text-xl xl:text-3xl font-medium
                    text-gradient bg-clip-text text-transparent bg-gradient-to-r from-custom-700 to-custom-500 no-m m-t-xs
                     overflow-hidden mb-1"
                    >
                        ระบบสมาชิก Online
                    </h1>
                    <h2
                        className="text-sm md:text-base lg:text-lg xl:text-xl font-normal
                    text-gradient bg-clip-text text-transparent bg-gradient-to-r from-custom-700 to-custom-500 no-m m-t-xs
                     overflow-hidden"
                    >
                        {window.config.coopNameTH}
                    </h2>
                </div>
            </div>
        </Fragment>
    );
}

export default WelcomeMember;
