import React, { Fragment } from "react";
import * as moment from "moment";
// import  mime from "mime-types";
// var mime = require('mime-types');
import escapeHtml from "escape-html";
import { Text } from "slate";

export const number_format = (val, digit = 2) => {
    var nf = new Intl.NumberFormat("th", {
        minimumFractionDigits: digit,
    });
    return nf.format(val);
};

export const convert_to_Thaidate = (
    val,
    format = "ll",
    fm = "YYYY-MM-DD",
    thaiyear = true
) => {
    let date = moment(val, fm, "th", true);
    if (date.isValid()) {
        if (thaiyear) {
            date.add(543, "years");
        }
        return date.format(format);
    }
};
export const getExtensionFile = (data) => {
    return null;
    // let result = mime.extension(data);
    // return result;
};
export const getWeekNumber = (d) => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    // return [d.getUTCFullYear(), weekNo];
    return weekNo;
};

export function loadImage(variable, category) {
    let imgCategoryList = [
        { name: "news", value: "news_content" },
        { name: "activity-gallary", value: "activity_content" },
        { name: "rule", value: "role_content" },
        { name: "Scholarships", value: "school_content" },
        { name: "achievement", value: "achievement_content" },
        { name: "report_center", value: "achievement_content" },
        { name: "notice", value: "notice_content" },
        { name: "news_letters", value: "letters_content" },
        { name: "business_report", value: "achievement_content" },
        { name: "download", value: "download_content" },
        { name: "default", value: "news_content" },
    ];

    const imgCategorySearch = (prop) => {
        if (prop) {
            let myColor = imgCategoryList.find((value, k) => {
                if (value.name == prop) {
                    return value;
                }
            });
            if (myColor) {
                return myColor.value;
            } else {
                return "news_content";
            }
        }
    };

    if (variable) {
        var image = new Image();
        var url_image = "mediafiles/" + variable;
        const contentTypeImg = [
            "image/bmp",
            "image/gif",
            "image/jpeg",
            "image/png",
        ];
        let defaultPath = "picture/";
        image.src = url_image;
        var http = new XMLHttpRequest();
        http.open("HEAD", image.src, false); // false = Synchronous
        http.send(null); // it will stop here until this http request is complete
        if (
            http.status === 200 &&
            contentTypeImg.includes(http.getResponseHeader("Content-Type"))
            // &&
            // // อันนี้แปลว่า keep alive
            // http.getResponseHeader("Connection") != "Keep-Alive"
        ) {
            return variable;
        } else {
            let img = `picture/Utils/ContentDefault/${imgCategorySearch(
                category
            )}.webp`;
            return `${img}`;
        }
    } else {
        let img = `picture/Utils/ContentDefault/${imgCategorySearch(
            category
        )}.webp`;
        return `${img}`;
    }
}

export const QuestionLimit = (val, limit = 80) => {
    if (val && val.length > 1) {
        let lengthStr = val.length;
        let GetStr = val;
        let AddPer = "";
        if (lengthStr > limit) {
            AddPer = "...";
            GetStr = GetStr.slice(0, limit);
        } else {
        }
        GetStr = GetStr + AddPer;
        return <Fragment>{GetStr}</Fragment>;
    }
};
export const QuestionLimitRaw = (val, limit = 80) => {
    if (val && val.length > 1) {
        let lengthStr = val.length;
        let GetStr = val;
        let AddPer = "";
        if (lengthStr > limit) {
            AddPer = "...";
            GetStr = GetStr.slice(0, limit);
        } else {
        }
        GetStr = GetStr + AddPer;
        return GetStr;
    }
};

export const ContentDefaultPath = "mediafiles/images/Utils/content_default/";
export const ContentDefault = [
    { name: "news", class: "bg-content-news", image: "content_news.jpg" },
    { name: "order", class: "bg-content-order", image: "content_order.jpg" },
    { name: "rules", class: "bg-content-rules", image: "content_rules.jpg" },
    {
        name: "overall_operation",
        class: "bg-content-overall_operation",
        image: "content_overall_operation.png",
    },
    {
        name: "coop_investment",
        class: "bg-content-coop_investment",
        image: "content_coop_investment.png",
    },

    {
        name: "coop_order",
        class: "bg-content-order",
        image: "content_coop_investment.png",
    },
    {
        name: "coop_act",
        class: "bg-content-coop_act",
        image: "content_coop_act.jpg",
    },
    {
        name: "walfare",
        class: "bg-content-walfare",
        image: "content_walfare.jpg",
    },
    { name: "notice", class: "bg-content-notice", image: "content_notice.jpg" },
    {
        name: "activity-gallary",
        class: "bg-content-activity-gallary",
        image: "content_activity-gallary.jpg",
    },
    { name: "video", class: "bg-content-video", image: "content_video.jpg" },
    {
        name: "bussiness_report",
        class: "bg-content-bussiness_report",
        image: "content_bussiness_report.jpg",
    },
    {
        name: "bussiness_report_monthy",
        class: "bg-content-bussiness_report_monthy",
        image: "content_bussiness_report_monthy.jpg",
    },
    {
        name: "download",
        class: "bg-content-download",
        image: "content_download.jpg",
    },
    {
        name: "photo",
        class: "bg-content-photo",
        image: "content_photo.jpg",
    },
    {
        name: "rag",
        class: "bg-content-rag",
        image: "content_rag.jpg",
    },
    {
        name: "scolarship",
        class: "bg-content-scolarship",
        image: "content_scolarship.jpg",
    },
    {
        name: "ref",
        class: "bg-content-ref",
        image: "content_ref.jpg",
    },
];

export const moment_datetime = (val) => {
    if (val != null) {
        if (val.toString() == "0000-00-00 00:00:00") {
            return " - ";
        }
        let datetime = val;
        // return datetime;
        let dateval = datetime.toString().slice(0, 10);
        let timeval = datetime.toString().slice(11, 19);
        if (timeval == null) {
            timeval = "00:00:00";
        }
        const regextime = /:/g;
        const regexdate = /-/g;
        const datenow = moment().format("YYYY-MM-DD");
        const timenow = moment().format("HH:mm:ss");

        const caltimenow = timenow.replace(regextime, "");
        const caldatenow = datenow.replace(regexdate, "");
        const caltimeval = timeval.replace(regextime, "");
        const caldataval = dateval.replace(regexdate, "");
        const fullcaldatetimeval = caldataval + caltimeval;

        let week = getWeekNumber(new Date());
        week = week * 7;
        var result = "";
        // 1100
        let dateCal = parseInt(caldatenow) - parseInt(caldataval);
        if (dateCal == 0) {
            return moment(caltimeval, "HHmmss", "th", true).fromNow();
        } else if (dateCal == 1) {
            let daycal =
                parseInt(caltimeval.slice(0, 4)) -
                parseInt(caltimenow.slice(0, 4));
            if (daycal <= 700) {
                let ok =
                    moment(
                        fullcaldatetimeval,
                        "YYYYMMDDHHmmss",
                        "th",
                        true
                    ).fromNow() +
                    " เวลา " +
                    timeval.slice(0, 5) +
                    " น.";
                // let ok = moment(fullcaldatetimeval,"YYYYMMDD",'th',true).fromNow()+' เวลา '+timeval.slice(0,5)+' น.';
                return ok;
            } else {
                let ok =
                    moment(
                        fullcaldatetimeval,
                        "YYYYMMDDHHmmss",
                        "th",
                        true
                    ).calendar() +
                    " เวลา " +
                    timeval.slice(0, 5) +
                    " น.";
                // let ok = moment(fullcaldatetimeval,'YYYYMMDD','th',true).calendar()+' เวลา '+timeval.slice(0,5)+' น.';
                return ok;
            }
        } else if (dateCal > 1 && dateCal <= 7) {
            // let ok = moment(fullcaldatetimeval,'YYYYMMDDHHmmss','th',true).calendar()+' น.';
            let ok =
                caldataval.slice(6, 8) +
                "  " +
                thai_month(caldataval.slice(4, 6)) +
                " เวลา " +
                timeval.slice(0, 5) +
                " น.";
            return ok;
        } else if (dateCal > 7 && dateCal < week) {
            // return moment(caldataval, 'YYYYMMDD','th',true).calendar();
            // let ok = thai_month(caldataval.slice(4,6))+'  '+caldataval.slice(6,8)+' เวลา '+timeval.slice(0,5)+' น.';
            let ok =
                caldataval.slice(6, 8) +
                "  " +
                thai_month(caldataval.slice(4, 6)) +
                " เวลา " +
                timeval.slice(0, 5) +
                " น.";
            return ok;
        } else {
            let date = moment(caldataval, "YYYYMMDD", "th", true);
            if (date.isValid()) {
                date.add(543, "years");
                return date.format("LL");
            }
        }
    } else {
        return " - ";
    }
};
export const content_sub_str = (str, length = 80) => {
    if (str && str) {
        if (str.length > length) {
            return str.substring(0, 80) + "..";
        } else {
            return str;
        }
    } else {
        return "";
    }
};
let thai_month = (ag_month = 0, format = "sub") => {
    if (ag_month.substring(0, 1) == 0) {
        ag_month = ag_month.trim();
    }
    let full_day = [
        "จันทร์",
        "อังคาร",
        "พุธ",
        "พฤหัสบดี",
        "ศุกค์",
        "เสาร์",
        "อาทิตย์",
    ];
    let full_month_th = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฏาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ];
    let sub_month_th = [
        "ม.ค.",
        "ก.พ.",
        "มี.ค",
        "เม.ย.",
        "พ.ค",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
    ];
    if (format == "sub") {
        return sub_month_th[ag_month - 1];
    } else if (format == "full") {
        return full_month_th[ag_month - 1];
    }
};
export const convert_to_Thaidatetime = (val) => {
    let date = moment(val, "YYYY-MM-DD HH:mm:ss", "th", true);
    if (date.isValid()) {
        date.add(543, "years");
        return date.format("ll");
    }
};

export const createMarkup = (html, success = true) => {
    // console.log(html);
    if (!success) {
        return {
            __html: "<center><h3>Loading ...</h3></center>",
        };
    }
    return { __html: html };
};
export const strip_tags = (str) => {
    str = str.toString();
    str = str.replace(/<\/?[^>]+>/gi, "");
    // return str.replace(/<\/?[^>]+>/gi, '');
    return str.replace(/&nbsp;/gi, " ");
};

export const useEventListener = (target, type, listener, ...options) => {
    React.useEffect(() => {
        const targetIsRef = target.hasOwnProperty("current");
        const currentTarget = targetIsRef ? target.current : target;
        if (currentTarget)
            currentTarget.addEventListener(type, listener, ...options);
        return () => {
            if (currentTarget)
                currentTarget.removeEventListener(type, listener, ...options);
        };
    }, [target, type, listener, options]);
};

export const Wordcount = (e) => {
    e.preventDefault();
    let newWrd;
    let txtbox = $("#membership_no");
    let Wrdlen = $("#membership_no").val().length;

    if (Wrdlen == 1) {
        newWrd = "00000" + txtbox.val();
        txtbox.val(newWrd);
    }
    if (Wrdlen == 2) {
        newWrd = "0000" + txtbox.val();
        txtbox.val(newWrd);
    }
    if (Wrdlen == 3) {
        newWrd = "000" + txtbox.val();
        txtbox.val(newWrd);
    }
    if (Wrdlen == 4) {
        newWrd = "00" + txtbox.val();
        txtbox.val(newWrd);
    }
    if (Wrdlen == 5) {
        newWrd = "0" + txtbox.val();
        txtbox.val(newWrd);
    }
    if (Wrdlen == 6) {
        newWrd = "" + txtbox.val();
        txtbox.val(newWrd);
    }
};

export const isInputNumber = (e) => {
    var char = String.fromCharCode(e.which);
    if (!/[0-9]/.test(char)) {
        e.preventDefault();
    }
};

export const LoanSignStatus = (e, item_type_code = "") => {
    let color;
    switch (e) {
        case 0:
            color = "gray";
            break;
        case -1:
            color = "green";
            break;
        case 1:
            // สหกรณ์ต้องการให้จ่ายเงินกู้เป็นตัวอักษรสีดำ.....
            if (item_type_code == "01") {
                color = "gray";
            } else {
                color = "red";
            }

            break;
        default:
            color = "gray";
    }
    return color;
};

export const DepSignStatus = (e) => {
    let color;
    switch (parseInt(e)) {
        case 0:
            color = "gray";
            break;
        case -1:
            color = "red";
            break;

        case 1:
            color = "green";
            break;
        default:
            color = "red";
    }
    return color;
};

export const ShareSignStatus = (e) => {
    let color;
    switch (parseInt(e)) {
        case 0:
            color = "gray";
            break;
        case -1:
            color = "red";
            break;
        case 1:
            color = "green";
            break;
        default:
            color = "gray";
    }
    return color;
};

export const remarkFoundWord = (text, remark, witchColumn = "Note") => {
    // int

    let SplitText = text.split(remark);
    let isRemarkFirstWord = false;
    let mapping = SplitText.map((val, i) => {
        if (val.length == 0) {
            if (SplitText.length - 1 == i) {
                isRemarkFirstWord = true;
                return `<mark>${remark}</mark>`;
            } else {
                return "";
            }
        } else {
            if (isRemarkFirstWord == true) {
                if (i % 2 == 1) {
                    return val + `<mark>${remark}</mark>`;
                } else {
                    return val;
                }
            } else {
                if (i % 2 == 1) {
                    return `<mark>${remark}</mark>` + val;
                } else {
                    return val;
                }
            }
        }
    }).join("");

    // ประกาศตัวแปร Cut String
    let finalResult = "";
    // การ cut string
    switch (witchColumn) {
        case "Question":
            finalResult = QuestionLimitRaw(mapping, 120);
            break;
        default:
            finalResult = QuestionLimitRaw(mapping, 80);
    }

    return finalResult;

    // return SplitText;
};
// For new Editor
export const serialize = (node) => {
    // console.log(node.children);
    if (Text.isText(node)) {
        let string = escapeHtml(node.text);
        let result = "";

        // console.log(string)
        if (node.bold) {
            result = `<strong>${result != "" ? result : string}</strong>`;
        }
        if (node.underline) {
            result = `<u>${result != "" ? result : string}</u>`;
        }
        if (node.italic) {
            result = `<em>${result != "" ? result : string}</em>`;
        }

        if (result == "") {
            result = string;
        }
        return result;
    }

    const children = node.children.map((n) => serialize(n)).join("");

    switch (node.type) {
        // case "quote":
        //     return `<blockquote><p>${children}</p></blockquote>`;
        case "paragraph":
            return `<p>${children}</p>`;
        // case "link":
        //     return `<a href="${escapeHtml(node.url)}">${children}</a>`;
        default:
            return children;
    }
};

const BLOCK_TAGS = {
    p: "paragraph",
    li: "list-item",
    ul: "bulleted-list",
    ol: "numbered-list",
    blockquote: "quote",
    pre: "code",
    h1: "heading-one",
    h2: "heading-two",
    h3: "heading-three",
    h4: "heading-four",
    h5: "heading-five",
    h6: "heading-six",
};

const MARK_TAGS = {
    strong: "bold",
    em: "italic",
    u: "underline",
    s: "strikethrough",
    code: "code",
};

/**
 * Serializer rules.
 *
 * @type {Array}
 */

const RULES = [
    {
        deserialize(el, next) {
            const block = BLOCK_TAGS[el.tagName.toLowerCase()];

            if (block) {
                return {
                    object: "block",
                    type: block,
                    nodes: next(el.childNodes),
                };
            }
        },
    },
    {
        deserialize(el, next) {
            const mark = MARK_TAGS[el.tagName.toLowerCase()];

            if (mark) {
                return {
                    object: "mark",
                    type: mark,
                    nodes: next(el.childNodes),
                };
            }
        },
    },
    {
        // Special case for code blocks, which need to grab the nested childNodes.
        deserialize(el, next) {
            if (el.tagName.toLowerCase() === "pre") {
                const code = el.childNodes[0];
                const childNodes =
                    code && code.tagName.toLowerCase() === "code"
                        ? code.childNodes
                        : el.childNodes;

                return {
                    object: "block",
                    type: "code",
                    nodes: next(childNodes),
                };
            }
        },
    },
    {
        // Special case for images, to grab their src.
        deserialize(el, next) {
            if (el.tagName.toLowerCase() === "img") {
                return {
                    object: "inline",
                    type: "image",
                    nodes: next(el.childNodes),
                    data: {
                        src: el.getAttribute("src"),
                    },
                };
            }
        },
    },
    {
        // Special case for links, to grab their href.
        deserialize(el, next) {
            if (el.tagName.toLowerCase() === "a") {
                return {
                    object: "inline",
                    type: "link",
                    nodes: next(el.childNodes),
                    data: {
                        href: el.getAttribute("href"),
                    },
                };
            }
        },
    },
];

const MapHtmlSlate = (nodeArray, joinHtml) => {
    return nodeArray
        .map((val) => {
            return `<${joinHtml}>${val}</${joinHtml}>`;
        })
        .join("");
};

export const IconPath = "/mediafiles/images/Utils/icon/";

export const ConvertFormatDate = (e) => {
    if (!e) {
        return null;
    } else {
        let dateYear = e.getFullYear();
        let dateMonth = e.getMonth();
        let dateDay = e.getDate();
        // -----------------------------
        if (dateMonth.toString().length < 2) {
            if (dateMonth.toString() == "9") {
                dateMonth = "10";
            } else {
                dateMonth = "0" + (dateMonth + 1);
            }
        } else {
            dateMonth += 1;
        }

        if (dateDay.toString().length < 2) {
            dateDay = "0" + dateDay;
        }
        // ------------------------------
        let fulldate = dateYear + "-" + dateMonth + "-" + dateDay;

        return fulldate;
    }
};
