import { HeaderNavConstants } from "../constants";

function onToggleCollapsed(bool) {
    return dispatch => {
        dispatch(toggleCollapsed(bool));
    };
}

function onToggleOpened(bool) {
    return dispatch => {
        dispatch(toggleOpened(bool));
    };
}

const toggleCollapsed = bool => {
    return {
        type: HeaderNavConstants.ON_COLLAPSED,
        payload: bool
    };
};

const toggleOpened = bool => {
    return {
        type: HeaderNavConstants.ON_OPEN,
        payload: bool
    };
};

export const HeaderNavAction = {
    onToggleCollapsed,
    onToggleOpened
};
