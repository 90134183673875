import react from "react";
const memstatus = `
        <g id="ข้อมูลส่วนตัว">
            <g>
                <rect
                    x="35.9"
                    y="58.1"
                    class="st0"
                    width="26.9"
                    height="23.7"
                />
            </g>
            <polygon
                class="st1"
                points="62.8,58.1 62.8,66.4 52.9,74.5 42.2,65.9 48.2,58.1 	"
            />
            <g>
                <ellipse
                    transform="matrix(0.9962 -8.719920e-02 8.719920e-02 0.9962 -3.3584 2.7407)"
                    class="st0"
                    cx="29.7"
                    cy="39.8"
                    rx="4.4"
                    ry="6.5"
                />

                <ellipse
                    transform="matrix(8.719920e-02 -0.9962 0.9962 8.719920e-02 23.5985 105.399)"
                    class="st0"
                    cx="69.3"
                    cy="39.8"
                    rx="6.5"
                    ry="4.4"
                />
            </g>
            <path
                class="st0"
                d="M70.4,29.9v6.8c0,1.6,0,2.9-0.3,4.4c-0.3,2.3-0.8,4.7-1.6,7c-1.8,5.5-4.7,10.7-8.6,14.9l-2.1,2.1
c-1.8,2.1-4.4,3.1-7,3.1h-2.9c-2.6,0-5.2-1-7-3.1L38.8,63c-2.9-3.1-5-6.5-6.8-10.2c-1.8-3.7-2.9-7.6-3.4-11.7
c-0.3-1.6-0.3-2.9-0.3-4.4c0-0.3,0-0.3,0-0.5v-6.3c0-2.1,0.3-3.9,0.8-5.7C31.8,15.3,39.9,9,49.2,9c9.6,0,17.7,6.5,20.1,15.1
C70.1,26,70.4,27.8,70.4,29.9z"
            />
            <rect
                x="29.7"
                y="79.2"
                class="st2"
                width="39.4"
                height="18.3"
            />
            <polygon
                class="st3"
                points="44.8,97.4 54.2,97.4 52.6,86.2 55.5,83.4 49.5,77.4 43.5,83.4 46.4,86.2 	"
            />
            <g>
                <path
                    class="st4"
                    d="M98,97.4v-5.7c0-7.6-4.7-14.6-12-17.2l-23.2-8.1l0,0l-3.1,31H98z"
                />
                <path
                    class="st4"
                    d="M39.1,97.4l-3.1-31L13,74.8C5.7,77.4,1,84.1,1,92v5.7h38.1V97.4z"
                />
            </g>
            <g>
                <path
                    class="st5"
                    d="M80.3,97.4v-0.3l-11-10.7c-0.3-0.3,0-0.5,0-0.5L80,81.3c0.3,0,0.3-0.3,0-0.5L62.8,66.4l-3.1,31H80.3z"
                />
                <path
                    class="st5"
                    d="M18.7,97.2v0.3h20.3l-3.1-31L19,80.5c-0.3,0.3-0.3,0.5,0,0.5l10.7,4.7c0.3,0,0.3,0.3,0,0.5L18.7,97.2z"
                />
            </g>
            <g>
                <polygon
                    class="st6"
                    points="49.5,77.4 62.8,66.4 60.7,88.6 		"
                />
                <polygon
                    class="st6"
                    points="49.5,77.4 35.9,66.4 38.3,88.8 		"
                />
            </g>
            <path
                class="st7"
                d="M26.8,34.9l2.1,6c0,0-0.8-18.3,6-18.3c0,0,10.2,4.2,14.9,5.2c0,0,10.4,1.3,15.6-5c0,0,4.2,2.3,2.9,7.3
c0,0,2.1,8.3,2.1,11l2.1-5.5c1-2.9,4.4-15.9-6-19c0,0-0.8-12-17.7-13.3c0,0-12.5-0.8-22.4,13.3l2.3-1
C28.6,15.3,23.9,18.2,26.8,34.9z"
            />
        </g>`;

const memshare = `<g id="ทะเบียนหุ้น">
<rect x="29.6" y="76" class="st11" width="39.8" height="7.6"/>
<g>
    <path class="st12" d="M79.7,93.7c0,1.4-0.8,2.6-2,3.4c-1.8,1-4.2,0.4-5.2-1.6L60,72.6l-0.4-0.8l-1.2-2.2L54.2,62h8.6l4.2,7.6
        l1.2,2.2l0.4,0.8l10.5,19.3C79.5,92.5,79.7,93.1,79.7,93.7z"/>
    <path class="st12" d="M44.5,62l-4,7.6l-1.2,2.2l-0.4,0.8L26.4,95.5c-1,1.8-3.4,2.6-5.2,1.6c-1.2-0.6-2-2-2-3.4
        c0-0.6,0.2-1.2,0.4-1.8l10.5-19.3l0.4-0.8l1.2-2.2l4.2-7.6L44.5,62L44.5,62z"/>
</g>
<rect x="7.5" y="9.6" class="st13" width="83.9" height="52.5"/>
<g>
    <path d="M91.2,2H7.7c-2,0-3.8,1.6-3.8,3.8l0,0c0,2,1.6,3.8,3.8,3.8h83.5c2,0,3.8-1.6,3.8-3.8l0,0C95,3.6,93.4,2,91.2,2z"/>
    <path d="M95,65.8c0,2-1.6,3.8-3.6,3.8H7.5c-1,0-2-0.4-2.6-1.2c-0.6-0.6-1-1.6-1-2.6c0-2,1.6-3.8,3.6-3.8h83.9c1,0,2,0.4,2.6,1.2
        C94.6,63.8,95,64.8,95,65.8z"/>
</g>
<rect x="7.5" y="9.6" class="st14" width="83.9" height="3"/>
<g>
    <polygon class="st11" points="32,69.6 40.5,69.6 39.3,71.8 38.9,72.6 30.2,72.6 30.8,71.8 		"/>
    <polygon class="st11" points="68.8,72.6 60,72.6 59.6,71.8 58.4,69.6 67,69.6 68.2,71.8 		"/>
</g>
<polyline class="st15" points="19.8,51.2 28.8,35.1 38.4,50.4 47.7,37.3 61.1,49.4 83,20.2 	"/>
<line class="st16" x1="13.1" y1="14.5" x2="13.1" y2="58.9"/>
<line class="st16" x1="13.4" y1="58.8" x2="85.8" y2="58.8"/>
</g>`;
const memloan = `<g id="เงินกู้">
<circle class="st17" cx="62.7" cy="27.2" r="23.1"/>
<circle class="st14" cx="62.7" cy="27.2" r="19"/>
<g>
    <path class="st18" d="M93.5,64.4c0,0-9.7,7.7-15.2,10.1c0,0-12,6.5-23.3,6.9H37.4c0,0-3.2-0.6-7.5,2.2c-0.6,0.4-1,0.8-1.6,1.2
        c-0.8,0.6-1.8,1.4-2.6,2.4L11.5,66.7c0,0,1.2-0.8,2.8-2.2c0.4-0.4,1-0.8,1.4-1.4c1.2-1,2.4-2.4,3.6-3.8c0,0,7.5-9.7,24.3-3.8
        c11.8,4.1,13.2,4.3,13.2,4.3s7.1,0.8,14,0.8c0,0,5.7,0,5.5,4.3c0,1.4-0.8,2.6-1.8,3.6c-1.4,1.2-4.1,2.6-8.9,3
        c-2,0.2-4.3,0.2-6.3,0c-5.3-0.4-11.5-1.4-12.8-0.6c0,0-1.6,1.2,4.7,2c0,0,4.7,0.6,13,0.8c0,0,15-0.6,14.4-11.1
        c0,0,5.3-3.2,8.3-4.5c0,0,8.5-5.1,10.5-1.8C97.6,56.3,100,59.8,93.5,64.4z"/>
</g>
<path class="st19" d="M74.1,63.4c-0.6-0.6-2.2-1-3-1h-1c-0.8,0-1.4,0-2.2,0v6.7c2.8-0.6,4.5-1.6,5.5-2.2c0.6-0.6,1-1.4,1.2-2.2
    C74.3,64,74.1,63.6,74.1,63.4z"/>
<path class="st20" d="M29.9,83.7c-0.6,0.4-1,0.8-1.6,1.2c-0.8,0.6-1.8,1.4-2.6,2.4L11.5,66.7c0,0,1.2-0.8,2.8-2.2
    c0.4-0.4,1-0.8,1.4-1.4L29.9,83.7z"/>
<rect x="9.3" y="61.2" transform="matrix(0.8238 -0.5668 0.5668 0.8238 -41.304 22.5535)" class="st21" width="12.8" height="33"/>
<circle class="st22" cx="22.2" cy="86.7" r="2.8"/>
<path class="st17" d="M70.5,29.1c-0.3-0.7-0.8-1.2-1.4-1.6c-0.6-0.4-1.4-0.7-2.2-0.8c0.8-0.1,1.5-0.4,2.1-0.7
    c0.6-0.4,1-0.9,1.3-1.4c0.3-0.6,0.5-1.3,0.5-2c0-1.1-0.2-2.1-0.7-2.9c-0.5-0.8-1.1-1.4-2-1.9c-0.8-0.4-1.9-0.6-3-0.6l0-2.6l-2.8,0
    l0,2.6l-1.8,0l-2.1,0l-1.6,0l-0.2,19.7l1.6,0l2.1,0l1.8,0l0,4.2l2.8,0l0-4.2l0,0c1.3,0,2.4-0.2,3.3-0.6c0.9-0.4,1.6-1,2.1-1.8
    c0.5-0.8,0.7-1.8,0.7-2.9l0,0C70.9,30.5,70.8,29.7,70.5,29.1z M66.4,21.1c0.4,0.4,0.6,0.9,0.6,1.5c0,0.7-0.2,1.3-0.6,1.6
    c-0.4,0.3-0.9,0.5-1.4,0.5l0-4.1C65.6,20.7,66.1,20.8,66.4,21.1z M60.4,20.5l1.8,0l0,4.2l-1.8,0L60.4,20.5z M60.3,33.1l0.1-4.7
    l1.8,0L62,33.1L60.3,33.1z M67.2,30.8c0,0.7-0.2,1.3-0.7,1.7c-0.4,0.3-1,0.5-1.6,0.6l0.1-4.7c0.4,0,0.8,0.1,1.1,0.3
    c0.4,0.2,0.7,0.5,0.9,0.8C67.1,29.8,67.2,30.3,67.2,30.8L67.2,30.8z"/>
</g>`;

const memdep = `<g id="เงินฝาก">
<path class="st23" d="M94,36.2v31.3c0,2.6-2,4.6-4.6,4.6H20.4c-2.6,0-4.6-2-4.6-4.6V36.2c0-2.6,2-4.6,4.6-4.6h68.9
    C91.9,31.7,94,33.8,94,36.2z"/>
<path class="st24" d="M20.4,59.8V44.1c4.4,0,7.9-3.6,7.9-7.9h53.4c0,4.4,3.6,7.9,7.9,7.9v15.6c-4.4,0-7.9,3.6-7.9,7.9H28.3
    C28.3,63.3,24.8,59.8,20.4,59.8z"/>
<g>
    <circle class="st25" cx="36.3" cy="51.8" r="2.6"/>
    <circle class="st25" cx="73.7" cy="51.8" r="2.6"/>
    <path class="st25" d="M64.9,51.8c0,6.9-4.4,12.5-9.9,12.5c-0.8,0-1.4-0.2-2.2-0.2c-4.4-1.2-7.7-6.3-7.7-12.3c0-0.2,0-0.6,0-0.8
        c0.4-6.5,4.6-11.7,9.9-11.7C60.4,39.4,64.9,44.9,64.9,51.8z"/>
</g>
<path class="st26" d="M54.3,56.8v-4.4c-1.4-0.4-2.4-1-3-1.8s-1-1.8-1-3c0-1.2,0.4-2.2,1-3c0.8-0.8,1.8-1.2,3-1.4v-1h1.6v1.2
    c1.2,0.2,2,0.6,2.6,1.2c0.6,0.6,1,1.4,1.2,2.6L57,47.5c-0.2-0.8-0.6-1.4-1.2-1.8v4.2c1.8,0.4,2.8,1,3.4,1.8c0.6,0.8,1,1.6,1,2.8
    s-0.4,2.4-1.2,3.2c-0.8,0.8-1.8,1.4-3.2,1.6v2h-1.6v-2c-1.2-0.2-2.2-0.6-3-1.4s-1.2-1.8-1.4-3.2l2.8-0.2c0.2,0.6,0.4,1,0.6,1.4
    C53.5,56.4,53.9,56.6,54.3,56.8z M54.3,45.9c-0.4,0.2-0.8,0.4-1,0.8c-0.2,0.4-0.4,0.8-0.4,1.2c0,0.4,0.2,0.8,0.4,1
    c0.2,0.4,0.6,0.6,1,0.8V45.9z M55.6,57c0.6,0,1-0.4,1.2-0.8c0.6-0.4,0.6-0.8,0.6-1.4c0-0.4-0.2-0.8-0.4-1.2
    c-0.2-0.4-0.8-0.6-1.4-0.8V57L55.6,57z"/>
<path class="st23" d="M85.1,20.4l7.3,30.3c0.6,2.4-1,4.9-3.4,5.5L22.2,72.4c-2.4,0.6-4.9-1-5.5-3.4L9.4,38.8
    c-0.6-2.4,1-4.9,3.4-5.5L79.6,17C82.2,16.4,84.5,18,85.1,20.4z"/>
<path class="st24" d="M19.2,60.3l-3.8-15.2c4.2-1,6.9-5.3,5.7-9.5L73.1,23c1,4.2,5.3,6.9,9.5,5.7l3.8,15.2c-4.2,1-6.9,5.3-5.7,9.5
    L28.7,66.1C27.8,61.9,23.4,59.4,19.2,60.3z"/>
<g>
    <circle class="st23" cx="32.9" cy="49.1" r="2.6"/>
    <circle class="st23" cx="69.1" cy="40.4" r="2.6"/>
    <path class="st23" d="M60.6,42.3c1.6,6.7-1.4,13.3-6.7,14.4C53.1,57,52.5,57,51.7,57c-4.6-0.2-9.1-4.2-10.5-10.1
        c0-0.2-0.2-0.6-0.2-0.8c-1.2-6.5,1.8-12.5,6.9-13.8C53.3,31.3,59,35.6,60.6,42.3z"/>
</g>
<path class="st26" d="M51.5,49.7l-1-4.4c-1.4,0-2.6-0.4-3.4-1c-0.8-0.6-1.4-1.6-1.6-2.6c-0.2-1.2-0.2-2.2,0.4-3.2
    c0.6-1,1.4-1.6,2.6-2l-0.2-1l1.4-0.4l0.2,1c1.2-0.2,2,0,2.8,0.4c0.8,0.4,1.4,1.2,1.8,2.2l-2.6,1c-0.4-0.8-1-1.2-1.6-1.4l1,4
    c1.8,0,3,0.4,3.8,1c0.8,0.6,1.2,1.4,1.6,2.6c0.4,1.2,0.2,2.4-0.4,3.4c-0.6,1-1.4,1.8-2.8,2.4l0.4,2L52.5,54l-0.4-1.8
    c-1.2,0.2-2.4,0-3.2-0.6c-1-0.6-1.6-1.4-2.2-2.8l2.6-1c0.2,0.6,0.6,1,1,1.2C50.5,49.5,50.9,49.7,51.5,49.7z M48.7,39
    c-0.4,0.2-0.6,0.6-0.8,1s-0.2,0.8,0,1.2c0,0.4,0.2,0.6,0.6,1c0.2,0.2,0.6,0.4,1.2,0.6L48.7,39z M52.9,49.5c0.4-0.2,0.8-0.6,1-1
    c0.2-0.4,0.2-1,0.2-1.4c-0.2-0.4-0.4-0.8-0.6-1c-0.4-0.2-0.8-0.4-1.6-0.4L52.9,49.5z"/>
<path class="st23" d="M71.9,6.3L86.1,34c1.2,2.2,0.2,4.9-2,6.1L23,71.8c-2.2,1.2-4.9,0.2-6.1-2L2.6,42.1c-1.2-2.2-0.2-4.9,2-6.1
    L65.7,4.6C68.1,3.4,70.9,4.2,71.9,6.3z"/>
<path class="st24" d="M17.3,60.9l-7.1-13.8c4-2,5.3-6.7,3.4-10.7L61,11.9c2,4,6.7,5.3,10.7,3.4l7.1,13.8c-4,2-5.3,6.7-3.4,10.7
    L27.9,64.1C26,60.5,21.2,59,17.3,60.9z"/>
<g>
    <circle class="st23" cx="27.9" cy="46.7" r="2.6"/>
    <circle class="st23" cx="61" cy="29.7" r="2.6"/>
    <path class="st23" d="M53.3,33.6c3.2,6.1,1.8,13.3-3,15.6c-0.6,0.4-1.4,0.6-2,0.8c-4.6,1-9.7-2-12.5-7.3c-0.2-0.2-0.2-0.4-0.4-0.6
        c-2.8-5.9-1.2-12.7,3.4-15C43.6,24.5,50.1,27.5,53.3,33.6z"/>
</g>
<path class="st14" d="M96.8,89.4c0,5.3-9.3,9.5-20.8,9.5c-11.3,0-20.6-4.4-20.6-9.5v-6.3H97v6.3H96.8z"/>
<ellipse class="st27" cx="76.2" cy="82.9" rx="20.8" ry="9.5"/>
<ellipse class="st14" cx="76.2" cy="82.9" rx="17" ry="7.9"/>
<path class="st27" d="M75,86.3v-3c-2-0.2-3.6-0.6-4.6-1.2c-1-0.6-1.4-1.2-1.4-2s0.6-1.4,1.6-2c1-0.6,2.6-0.8,4.4-1v-0.8h2.2v0.8
    c1.6,0,3,0.4,4,0.8c1,0.4,1.6,1,1.8,1.8l-4,0.2c-0.2-0.6-0.8-1-1.8-1.2v2.8c2.6,0.4,4.2,0.8,5.1,1.2c1,0.4,1.4,1.2,1.4,2
    s-0.6,1.6-1.8,2.2c-1.2,0.6-2.8,1-4.7,1.2v1.4H75V88c-1.8-0.2-3.2-0.4-4.4-1c-1.2-0.6-1.8-1.2-2.2-2.2l4.2-0.2c0.2,0.4,0.4,0.8,1,1
    C74,86.1,74.4,86.3,75,86.3z M75,78.7c-0.6,0-1.2,0.2-1.4,0.4c-0.4,0.2-0.6,0.4-0.6,0.8c0,0.2,0.2,0.4,0.6,0.8
    c0.4,0.2,0.8,0.4,1.6,0.6v-2.6H75z M77.4,86.5c0.8,0,1.4-0.2,2-0.6c0.4-0.2,0.8-0.6,0.8-1c0-0.4-0.2-0.6-0.6-0.8
    c-0.4-0.2-1-0.4-2-0.6v3L77.4,86.5L77.4,86.5z"/>
<path class="st14" d="M96.8,81.3c0,5.3-9.3,9.5-20.8,9.5c-11.3,0.2-20.6-4.2-20.6-9.5V75H97v6.3H96.8z"/>
<ellipse class="st27" cx="76.2" cy="75" rx="20.8" ry="9.5"/>
<ellipse class="st14" cx="76.2" cy="75" rx="17" ry="7.9"/>
<path class="st27" d="M75,78.3v-3c-2-0.2-3.6-0.6-4.6-1.2c-1-0.6-1.4-1.2-1.4-2s0.6-1.4,1.6-2s2.6-0.8,4.4-1v-0.8h2.2v0.8
    c1.6,0,3,0.4,4,0.8c1,0.4,1.6,1,1.8,1.8L79,72c-0.2-0.6-0.8-1-1.8-1.2v2.8c2.6,0.4,4.2,0.8,5.1,1.2c1,0.4,1.4,1.2,1.4,2
    s-0.6,1.6-1.8,2.2c-1.2,0.6-2.8,1-4.7,1.2v1.4H75v-1.4c-1.8-0.2-3.2-0.4-4.4-1c-1.2-0.6-1.8-1.2-2.2-2.2l4.2-0.2
    c0.2,0.4,0.4,0.8,1,1C74,78,74.4,78.2,75,78.3z M75,70.8c-0.6,0-1.2,0.2-1.4,0.4c-0.4,0.2-0.6,0.4-0.6,0.8c0,0.2,0.2,0.4,0.6,0.8
    c0.4,0.2,0.8,0.4,1.6,0.6v-2.6H75z M77.4,78.3c0.8,0,1.4-0.2,2-0.6c0.4-0.2,0.8-0.6,0.8-1c0-0.4-0.2-0.6-0.6-0.8
    c-0.4-0.2-1-0.4-2-0.6v3L77.4,78.3L77.4,78.3z"/>
<path class="st14" d="M96.8,73.4c0,5.3-9.3,9.5-20.8,9.5s-20.8-4.4-20.8-9.5v-6.3h41.5V73.4z"/>
<ellipse class="st27" cx="76.2" cy="66.9" rx="20.8" ry="9.5"/>
<ellipse class="st14" cx="76.2" cy="66.9" rx="17" ry="7.9"/>
<path class="st27" d="M75,70.4v-3.2c-2-0.2-3.6-0.6-4.6-1.2c-1-0.6-1.4-1.2-1.4-2s0.6-1.4,1.6-2c1-0.6,2.6-0.8,4.4-1v-0.8h2.2v1
    c1.6,0,3,0.4,4,0.8c1,0.4,1.6,1,1.8,1.8l-4,0.2c-0.2-0.6-0.8-1-1.8-1.2v2.8c2.6,0.4,4.2,0.8,5.1,1.2c1,0.4,1.4,1.2,1.4,2
    S83.2,70.4,82,71c-1.2,0.6-2.8,1-4.7,1.2v1.4H75v-1.4c-1.8-0.2-3.2-0.4-4.4-1c-1.2-0.6-1.8-1.2-2.2-2.2l4.2-0.2
    c0.2,0.4,0.4,0.8,1,1C74,70,74.4,70.2,75,70.4z M75,62.7c-0.6,0-1.2,0.2-1.4,0.4c-0.4,0.2-0.6,0.4-0.6,0.8c0,0.2,0.2,0.4,0.6,0.8
    c0.4,0.2,0.8,0.4,1.6,0.6v-2.6H75z M77.4,70.4c0.8,0,1.4-0.2,2-0.6c0.4-0.2,0.8-0.6,0.8-1c0-0.4-0.2-0.6-0.6-0.8
    c-0.4-0.2-1-0.4-2-0.6v3H77.4L77.4,70.4z"/>
<path class="st14" d="M96.8,65.3c0,5.3-9.3,9.5-20.8,9.5c-11.3,0.2-20.6-4.2-20.6-9.5V59H97v6.3H96.8z"/>
<ellipse class="st27" cx="76.2" cy="59" rx="20.8" ry="9.5"/>
<ellipse class="st14" cx="76.2" cy="59" rx="17" ry="7.9"/>
<path class="st27" d="M75,62.3v-3c-2-0.2-3.6-0.6-4.6-1.2c-1-0.6-1.4-1.2-1.4-2s0.6-1.4,1.6-2c1-0.6,2.6-0.8,4.4-1v-0.8h2.2v0.8
    c1.6,0,3,0.4,4,0.8c1,0.4,1.6,1,1.8,1.8L79,56c-0.2-0.6-0.8-1-1.8-1.2v2.8c2.6,0.4,4.2,0.8,5.1,1.2c1,0.4,1.4,1.2,1.4,2
    s-0.6,1.6-1.8,2.2s-2.8,1-4.7,1.2v1.2H75v-1.4c-1.8-0.2-3.2-0.4-4.4-1c-1.2-0.6-1.8-1.2-2.2-2.2l4.2-0.2c0.2,0.4,0.4,0.8,1,1
    C74,61.9,74.4,62.1,75,62.3z M75,54.8c-0.6,0-1.2,0.2-1.4,0.4c-0.4,0.2-0.6,0.4-0.6,0.8c0,0.2,0.2,0.4,0.6,0.8
    c0.4,0.2,0.8,0.4,1.6,0.6v-2.6H75z M77.4,62.5c0.8,0,1.4-0.2,2-0.6c0.4-0.2,0.8-0.6,0.8-1c0-0.4-0.2-0.6-0.6-0.8
    c-0.4-0.2-1-0.4-2-0.6v3L77.4,62.5L77.4,62.5z"/>
<path class="st14" d="M96.8,57.4c0,5.3-9.3,9.5-20.8,9.5c-11.3,0-20.6-4.2-20.6-9.5V51H97v6.3H96.8z"/>
<ellipse class="st27" cx="76.2" cy="51" rx="20.8" ry="9.5"/>
<ellipse class="st14" cx="76.2" cy="51" rx="17" ry="7.9"/>
<path class="st26" d="M49.4,37.4C49,37.2,48.5,37,48.1,37c-0.5,0-1,0-1.5,0.3c0.4-0.3,0.7-0.7,0.9-1.1c0.2-0.4,0.3-0.8,0.3-1.2
    c0-0.4-0.1-0.8-0.4-1.3c-0.3-0.6-0.8-1.1-1.3-1.4c-0.5-0.3-1.1-0.4-1.7-0.4c-0.6,0-1.2,0.3-1.9,0.6l-0.8-1.4l-1.5,0.9l0.8,1.4
    l-1,0.5l-1.2,0.6l-0.9,0.5L44.1,46l0.9-0.5l1.2-0.6l1-0.5l1.3,2.3l1.5-0.9l-1.3-2.3l0,0c0.7-0.4,1.2-0.9,1.6-1.4
    c0.4-0.5,0.6-1.1,0.6-1.7s-0.2-1.2-0.5-1.8l0,0C50.1,38.1,49.7,37.7,49.4,37.4z M44.6,34.3c0.3,0.1,0.6,0.3,0.8,0.6
    c0.2,0.4,0.3,0.8,0.2,1.1c-0.1,0.3-0.3,0.5-0.6,0.7l-1.3-2.3C44,34.4,44.3,34.3,44.6,34.3z M41.1,35.9l1-0.5l1.3,2.3l-1,0.5
    L41.1,35.9z M45,42.9l-1.5-2.6l1-0.5l1.5,2.6L45,42.9z M48.1,39.4c0.2,0.4,0.3,0.8,0.1,1.2c-0.1,0.3-0.4,0.6-0.7,0.8l-1.4-2.6
    c0.2-0.1,0.5-0.2,0.7-0.2c0.3,0,0.5,0,0.8,0.2C47.7,38.9,47.9,39.1,48.1,39.4L48.1,39.4z"/>
<path class="st27" d="M80.4,52.1c-0.2-0.3-0.5-0.6-0.9-0.8c-0.4-0.2-0.9-0.3-1.4-0.4c0.5-0.1,1-0.2,1.3-0.4
    c0.4-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.6,0.3-1c0-0.5-0.2-1-0.5-1.4c-0.3-0.4-0.7-0.7-1.3-0.9c-0.5-0.2-1.2-0.3-1.9-0.3l0-1.3
    L75,45l0,1.3l-1.1,0l-1.3,0l-1,0l0.2,9.7l1,0l1.3,0l1.1,0l0,2l1.8,0l0-2l0,0c0.8,0,1.5-0.1,2.1-0.4c0.6-0.2,1-0.5,1.3-0.9
    s0.4-0.9,0.4-1.4l0,0C80.7,52.8,80.6,52.4,80.4,52.1z M77.7,48.3c0.3,0.2,0.4,0.4,0.4,0.7c0,0.3-0.1,0.6-0.4,0.8
    c-0.2,0.2-0.5,0.2-0.9,0.3l0-2C77.2,48.1,77.5,48.1,77.7,48.3z M73.9,48.1l1.1,0l0,2.1l-1.1,0L73.9,48.1z M74,54.2l0-2.3l1.1,0
    l0,2.3L74,54.2z M78.4,53c0,0.4-0.1,0.6-0.4,0.9c-0.2,0.2-0.6,0.3-1,0.3l0-2.3c0.3,0,0.5,0,0.7,0.1c0.3,0.1,0.5,0.2,0.6,0.4
    C78.3,52.5,78.4,52.7,78.4,53L78.4,53z"/>
</g>`;

const memkep = `<g id="เรียกเก็บประจำเดือน">
<path class="st14" d="M93.2,95H8.8C6.7,95,5,93.3,5,91.2V16.2c0-2.1,1.7-3.8,3.8-3.8h84.3c2.1,0,3.8,1.7,3.8,3.8v74.9
    C97,93.3,95.3,95,93.2,95z"/>
<path class="st26" d="M90.3,34.2H11.7c-1.2,0-1.9,0.8-1.9,1.9v52.1c0,1.2,0.8,1.9,1.9,1.9h65.2l15.3-15.3V36.2
    C92.2,35.2,91.4,34.2,90.3,34.2z"/>
<path class="st9" d="M76.9,90.2l15.3-15.3H78.6c-1,0-1.7,0.8-1.7,1.7V90.2z"/>
<g>
    <circle class="st27" cx="20.3" cy="19.7" r="4"/>
    <circle class="st27" cx="40.8" cy="19.7" r="4"/>
    <circle class="st27" cx="61.2" cy="19.7" r="4"/>
    <circle class="st27" cx="81.7" cy="19.7" r="4"/>
</g>
<g>
    <path class="st28" d="M20.3,21.8L20.3,21.8c-1.3,0-2.5-1.2-2.5-2.5V5.5c-0.2-1.3,1-2.5,2.5-2.5l0,0c1.3,0,2.5,1.2,2.5,2.5v13.6
        C22.8,20.6,21.7,21.8,20.3,21.8z"/>
    <path class="st28" d="M40.8,21.8L40.8,21.8c-1.3,0-2.5-1.2-2.5-2.5V5.5c0-1.3,1.2-2.5,2.5-2.5l0,0c1.3,0,2.5,1.2,2.5,2.5v13.6
        C43.3,20.6,42.2,21.8,40.8,21.8z"/>
    <path class="st28" d="M61.2,21.8L61.2,21.8c-1.3,0-2.5-1.2-2.5-2.5V5.5c0-1.3,1.2-2.5,2.5-2.5l0,0c1.3,0,2.5,1.2,2.5,2.5v13.6
        C63.8,20.6,62.7,21.8,61.2,21.8z"/>
    <path class="st28" d="M81.7,21.8L81.7,21.8c-1.3,0-2.5-1.2-2.5-2.5V5.5c0-1.3,1.2-2.5,2.5-2.5l0,0c1.3,0,2.5,1.2,2.5,2.5v13.6
        C84.3,20.6,83.2,21.8,81.7,21.8z"/>
</g>
<g>
    <rect x="35.1" y="40" class="st9" width="12.1" height="10.9"/>
    <rect x="54.8" y="40" class="st9" width="12.1" height="10.9"/>
    <rect x="74.4" y="40" class="st9" width="12.1" height="10.9"/>
    <rect x="15.5" y="56.9" class="st9" width="12.1" height="10.9"/>
</g>
<rect x="35.1" y="56.9" class="st29" width="12.1" height="10.9"/>
<g>
    <rect x="54.8" y="56.9" class="st9" width="12.1" height="10.9"/>
    <rect x="74.4" y="56.9" class="st9" width="12.1" height="10.9"/>
    <rect x="15.5" y="73.5" class="st9" width="12.1" height="10.9"/>
    <rect x="35.1" y="73.5" class="st9" width="12.1" height="10.9"/>
    <rect x="54.8" y="73.5" class="st9" width="12.1" height="10.9"/>
</g>
</g>`;

const memcoll = `<g id="ค้ำประกัน">
<g>
    <path class="st18" d="M94.5,65.4c0,0-9.7,7.7-15.2,10.1c0,0-12,6.5-23.3,6.9H38.4c0,0-3.2-0.6-7.5,2.2c-0.6,0.4-1,0.8-1.6,1.2
        c-0.8,0.6-1.8,1.4-2.6,2.4L12.5,67.7c0,0,1.2-0.8,2.8-2.2c0.4-0.4,1-0.8,1.4-1.4c1.2-1,2.4-2.4,3.6-3.8c0,0,7.5-9.7,24.3-3.8
        c11.8,4.1,13.2,4.3,13.2,4.3s7.1,0.8,14,0.8c0,0,5.7,0,5.5,4.3c0,1.4-0.8,2.6-1.8,3.6c-1.4,1.2-4.1,2.6-8.9,3
        c-2,0.2-4.3,0.2-6.3,0c-5.3-0.4-11.5-1.4-12.8-0.6c0,0-1.6,1.2,4.7,2c0,0,4.7,0.6,13,0.8c0,0,15-0.6,14.4-11.1
        c0,0,5.3-3.2,8.3-4.5c0,0,8.5-5.1,10.5-1.8C98.6,57.3,101,60.8,94.5,65.4z"/>
</g>
<path class="st27" d="M75.1,64.4c-0.6-0.6-2.2-1-3-1h-1c-0.8,0-1.4,0-2.2,0v6.7c2.8-0.6,4.5-1.6,5.5-2.2c0.6-0.6,1-1.4,1.2-2.2
    C75.3,65,75.1,64.6,75.1,64.4z"/>
<path class="st30" d="M30.9,84.7c-0.6,0.4-1,0.8-1.6,1.2c-0.8,0.6-1.8,1.4-2.6,2.4L12.5,67.7c0,0,1.2-0.8,2.8-2.2
    c0.4-0.4,1-0.8,1.4-1.4L30.9,84.7z"/>

    <rect x="10.3" y="62.2" transform="matrix(0.8238 -0.5668 0.5668 0.8238 -41.6946 23.2965)" class="st28" width="12.8" height="33"/>
<circle class="st31" cx="23.2" cy="87.7" r="2.8"/>
<g>
    <path class="st25" d="M90,41.3c0,12-9.8,17.2-21.9,17.2c-12.1,0-21.9-5.2-21.9-17.2c0-6.8,7.1-16,13.1-22.7
        c2.7-2.9,5.1-5.3,6.7-6.8c1.3-1.3,2.1-1.9,2.1-1.9s0.8,0.7,2.1,1.9c1.6,1.5,4,3.9,6.7,6.8C82.9,25.3,90,34.4,90,41.3z"/>
    <path class="st25" d="M62.2,15.5H74c2.4-2.4,3.5-5.8,4.2-9.3c0.2-1.5-1.2-2.7-2.5-2c-2.9,1.4-4.5,0.7-6.4-0.8
        c-0.7-0.6-1.6-0.6-2.3,0c-1.9,1.5-3.5,2.2-6.4,0.8c-1.4-0.6-2.9,0.6-2.5,2C58.8,9.7,59.8,12.9,62.2,15.5z"/>
</g>
<path class="st32" d="M59.2,7.4c-0.2-1.5,1.2-2.7,2.5-2c2.9,1.4,4.5,0.7,6.4-0.8c0.7-0.6,1.6-0.6,2.3,0c1.9,1.5,3.5,2.2,6.4,0.8
    c0.5-0.2,0.9-0.2,1.4-0.1c-0.3-0.9-1.4-1.6-2.5-1c-2.9,1.4-4.5,0.7-6.4-0.8c-0.7-0.6-1.6-0.6-2.3,0c-1.9,1.5-3.5,2.2-6.4,0.8
    c-1.4-0.6-2.9,0.6-2.5,2c0.7,3.5,1.7,6.7,4.2,9.3h0.2C60.7,13.2,59.8,10.4,59.2,7.4z"/>
<circle class="st33" cx="68.1" cy="38.7" r="10.5"/>
<path class="st34" d="M58.8,39.9c0-5.8,4.7-10.5,10.5-10.5c2.5,0,5,0.9,6.8,2.5c-2-2.2-4.7-3.7-8-3.7c-5.8,0-10.5,4.7-10.5,10.5
    c0,3.2,1.4,6,3.7,8C59.8,44.9,58.8,42.4,58.8,39.9z"/>
<path class="st33" d="M75.7,17.1h-15c-1,0-1.9-0.8-1.9-1.9l0,0c0-1,0.8-1.9,1.9-1.9h15.2c1,0,1.9,0.8,1.9,1.9l0,0
    C77.5,16.3,76.7,17.1,75.7,17.1z"/>
<path class="st28" d="M71.9,40c-0.2-0.3-0.4-0.6-0.7-0.8c-0.3-0.2-0.7-0.4-1.2-0.4c0.4-0.1,0.8-0.2,1.1-0.4
    c0.3-0.2,0.5-0.4,0.7-0.8c0.2-0.3,0.2-0.7,0.2-1.1c0-0.6-0.1-1.1-0.4-1.5c-0.2-0.4-0.6-0.7-1.1-1c-0.4-0.2-1-0.3-1.6-0.3l0-1.4
    l-1.5,0l0,1.3l-0.9,0l-1.1,0l-0.8,0l-0.1,10.3l0.8,0l1.1,0l0.9,0l0,2.2l1.5,0l0-2.2l0,0c0.7,0,1.2-0.1,1.7-0.3
    c0.5-0.2,0.8-0.5,1.1-0.9c0.3-0.4,0.4-0.9,0.4-1.5l0,0C72.1,40.7,72.1,40.3,71.9,40z M69.8,35.8c0.2,0.2,0.3,0.4,0.3,0.8
    c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.2-0.4,0.2-0.7,0.3l0-2.2C69.3,35.6,69.6,35.7,69.8,35.8z M66.7,35.5l0.9,0l0,2.2l-0.9,0L66.7,35.5z
     M66.6,42.1l0-2.5l0.9,0l0,2.5L66.6,42.1z M70.2,40.9c0,0.4-0.1,0.7-0.4,0.9C69.6,42,69.3,42,69,42.1l0-2.4c0.2,0,0.4,0.1,0.6,0.1
    c0.2,0.1,0.4,0.2,0.5,0.4C70.1,40.4,70.2,40.6,70.2,40.9L70.2,40.9z"/>
</g>`;

const memgain = `<g id="ผู้รับผลประโยชน์">
<path class="st33" d="M62,64.9v-5.7c0-5.7-4.6-10.4-10.4-10.4l0,0c-5.7,0-10.4,4.6-10.4,10.4v5.7c0,1.5-0.5,3.4-1.1,4.6
    c-0.2,0.4,0.1,0.9,0.5,0.9h21.9c0.4,0,0.7-0.5,0.5-0.9C62.5,68.3,62,66.4,62,64.9z"/>
<g>
    <rect x="48.1" y="66" class="st2" width="7.2" height="9.1"/>
</g>
<polygon class="st34" points="55.3,66 55.3,69.1 53.7,72.2 52,68.9 53,66 	"/>
<path class="st35" d="M58.8,62.4c-0.4,3.5-3.4,6.3-7,6.3c-3.9,0-7.1-3.2-7.1-7.1v-4.6c0-1.7,0.7-3.3,1.7-4.4
    C48.1,56.3,52.3,60.7,58.8,62.4z"/>
<path class="st36" d="M64.8,78.8c0-2.9-1.3-5.6-3.2-6.6l-5.7-2.8l-0.2-0.5c-4.2,2.8-7.7,0-7.7,0l0,0.3l-5.9,3c-2,1-3.2,3.6-3.2,6.5
    V81h7.6h2.6H57l0,0h7.7V78.8z"/>
<path class="st37" d="M94.7,75.1c-3.6-13.2-3.5-14.2-1-26.5c0.2-1,0.3-2.1,0.3-3.2c0-8-6.5-14.4-14.4-14.4s-14.4,6.5-14.4,14.4
    c0,1.1,0.1,2.1,0.3,3.2c2.5,12.4,2.6,13.3-1,26.5c-0.4,1.4-0.6,2.9-0.6,4.4c0,8.7,7,6.6,15.7,6.6s15.7,2.1,15.7-6.6
    C95.4,78,95.2,76.5,94.7,75.1z"/>
<path class="st38" d="M94.7,75.1c-3.6-13.2-3.5-14.2-1-26.5c0.2-1,0.3-2.1,0.3-3.2c0-8-6.5-14.4-14.4-14.4c-0.4,0-0.7,0-1.1,0
    c6.5,0.6,11.6,6.8,11.6,14.4c0,1.1-0.1,2.1-0.3,3.2c-2.2,12.4-2.3,13.3,0.9,26.5c0.4,1.4,0.6,2.9,0.6,4.4c0,5.2-2.2,6.6-5.6,6.8
    c5.7,0.2,9.8-0.2,9.8-6.8C95.4,78,95.2,76.5,94.7,75.1z"/>
<g>
    <rect x="18.9" y="61" class="st2" width="13.7" height="12.1"/>
</g>
<polygon class="st34" points="32.6,61 32.6,65.2 27.5,69.3 22.1,65 25.2,61 	"/>
<g>

        <ellipse transform="matrix(0.9962 -8.719920e-02 8.719920e-02 0.9962 -4.4461 1.5683)" class="st2" cx="15.7" cy="51.7" rx="2.3" ry="3.3"/>

        <ellipse transform="matrix(8.719920e-02 -0.9962 0.9962 8.719920e-02 -18.7039 82.9485)" class="st2" cx="35.9" cy="51.7" rx="3.3" ry="2.3"/>
</g>
<path class="st2" d="M36.4,46.6v3.5c0,0.8,0,1.5-0.1,2.3c-0.1,1.2-0.4,2.4-0.8,3.6c-0.9,2.8-2.4,5.4-4.4,7.6l-1.1,1.1
    c-0.9,1.1-2.3,1.6-3.6,1.6H25c-1.3,0-2.7-0.5-3.6-1.6l-1.1-1.1c-1.5-1.6-2.5-3.3-3.5-5.2c-0.9-1.9-1.5-3.9-1.7-6
    c-0.1-0.8-0.1-1.5-0.1-2.3c0-0.1,0-0.1,0-0.3v-3.2c0-1.1,0.1-2,0.4-2.9c1.3-4.5,5.4-7.7,10.2-7.7c4.9,0,9,3.3,10.2,7.7
    C36.3,44.6,36.4,45.6,36.4,46.6z"/>
<rect x="15.7" y="71.7" class="st39" width="20.1" height="9.3"/>
<polygon class="st40" points="23.4,81 28.2,81 27.4,75.3 28.9,73.9 25.8,70.8 22.8,73.9 24.2,75.3 	"/>
<g>
    <path class="st7" d="M50.5,81v-2.9c0-3.9-2.4-7.4-6.1-8.8l-11.8-4.1l0,0L31,81H50.5z"/>
    <path class="st7" d="M20.5,81l-1.6-15.8L7.2,69.5c-3.7,1.3-6.1,4.8-6.1,8.8v2.9h19.4V81z"/>
</g>
<g>
    <path class="st8" d="M41.5,81v-0.1l-5.6-5.4c-0.1-0.1,0-0.3,0-0.3l5.4-2.4c0.1,0,0.1-0.1,0-0.3l-8.8-7.3L31,81H41.5z"/>
    <path class="st8" d="M10.1,80.9V81h10.4l-1.6-15.8l-8.6,7.2c-0.1,0.1-0.1,0.3,0,0.3l5.4,2.4c0.1,0,0.1,0.1,0,0.3L10.1,80.9z"/>
</g>
<g>
    <polygon class="st9" points="25.8,70.8 32.6,65.2 31.5,76.5 		"/>
    <polygon class="st9" points="25.8,70.8 18.9,65.2 20.1,76.6 		"/>
</g>
<path class="st41" d="M14.3,49.2l1.1,3.1c0,0-0.4-9.3,3.1-9.3c0,0,5.2,2.1,7.6,2.7c0,0,5.3,0.7,8-2.5c0,0,2.1,1.2,1.5,3.7
    c0,0,1.1,4.3,1.1,5.6l1.1-2.8c0.5-1.5,2.3-8.1-3.1-9.7c0,0-0.4-6.1-9-6.8c0,0-6.4-0.4-11.4,6.8l1.2-0.5
    C15.2,39.2,12.8,40.7,14.3,49.2z"/>
<g>
    <rect x="74.1" y="58.1" class="st2" width="10.8" height="13.6"/>
</g>
<polygon class="st34" points="84.8,58.1 84.8,62.9 82.5,67.5 79.9,62.6 81.3,58.1 	"/>
<rect x="69.2" y="70.2" class="st39" width="22.5" height="10.5"/>
<polygon class="st40" points="77.6,80.7 81.4,80.7 80.8,74.2 81.9,72.6 79.5,69.2 77.1,72.6 78.2,74.2 	"/>
<g>
    <path class="st7" d="M99,80.7v-3.3c0-4.3-1.9-8.4-4.8-9.9l-9.3-4.6l0,0l-1.3,17.8H99z"/>
    <path class="st7" d="M75.3,80.7l-1.3-17.8l-9.2,4.8C61.9,69.2,60,73,60,77.5v3.3h15.3V80.7z"/>
</g>
<g>
    <path class="st8" d="M91.9,80.7v-0.1l-4.4-6.1c-0.1-0.1,0-0.3,0-0.3l4.3-2.7c0.1,0,0.1-0.1,0-0.3l-6.9-8.2l-1.3,17.8H91.9z"/>
    <path class="st8" d="M67.1,80.5v0.1h8.2l-1.3-17.8L67.2,71c-0.1,0.1-0.1,0.3,0,0.3l4.3,2.7c0.1,0,0.1,0.1,0,0.3L67.1,80.5z"/>
</g>
<g>
    <polygon class="st9" points="79.5,69.2 84.8,62.9 84,75.6 		"/>
    <polygon class="st9" points="79.5,69.2 74.1,62.9 75,75.7 		"/>
</g>
<path class="st42" d="M-78.2,60.8h-7.4c-0.7,0-1.3-0.3-1.9-0.1c-4.2,0.9-7.3,4.6-7.3,9.1v8.9c0,0.4,0.3,0.7,0.7,0.7h24.5
    c0.4,0,0.7-0.3,0.7-0.7v-8.9c0-4.4-3.1-8.2-7.3-9.1C-76.9,60.6-77.6,60.8-78.2,60.8z"/>
<path class="st33" d="M-69.3,48v-6.9c0-7-5.7-12.6-12.6-12.6l0,0c-7,0-12.6,5.7-12.6,12.6V48c0,1.9-0.6,4.1-1.4,5.6
    c-0.3,0.5,0.1,1.1,0.6,1.1h26.7c0.5,0,0.9-0.6,0.6-1.1C-68.7,52.1-69.3,49.9-69.3,48z"/>
<path class="st35" d="M-72.6,44.6v2c0,5.2-4.2,9.4-9.4,9.4c-5.2,0-9.4-4.2-9.4-9.4v-6.1c0-0.3,0-0.7,0.1-1h1.2c1.3,0,2.4,0.8,2.9,2
    c0.3,0.9,1,1.7,1.7,2.3c0.8,0.5,1.7,0.9,2.7,0.9L-72.6,44.6L-72.6,44.6z"/>
<path class="st43" d="M-76.3,60.7c-0.6-0.1-1.3-0.2-1.9-0.2h-7.4c-0.7,0-1.3,0.1-1.9,0.2c0.4,3.4,2.6,6.2,5.6,7.5
    C-78.9,66.9-76.7,64.1-76.3,60.7z"/>
<circle class="st44" cx="-11.7" cy="48.9" r="4.7"/>
<path class="st35" d="M-21,55.9L-21,55.9c-5.2,0-9.4-4.2-9.4-9.4v-6.1c0-4.8,3.9-8.7,8.7-8.7h1.4c4.8,0,8.7,3.9,8.7,8.7v6.1
    C-11.7,51.7-15.9,55.9-21,55.9z"/>
<path class="st45" d="M-17.3,60.5h-7.4c-5.1,0-9.3,4.1-9.3,9.3v8.9c0,0.4,0.3,0.7,0.7,0.7h24.5c0.4,0,0.7-0.3,0.7-0.7v-8.9
    C-8.1,64.6-12.2,60.5-17.3,60.5z"/>
<path class="st33" d="M-11.7,40.5v6.1c-13.4-1.2-14.5-8.4-14.5-8.4v4.6h-4.2v-2.3c0-4.8,3.9-8.7,8.7-8.7h1.4
    C-15.5,31.8-11.7,35.7-11.7,40.5z"/>
<path class="st43" d="M-15.4,60.7c-0.6-0.1-1.3-0.2-1.9-0.2h-7.4c-0.7,0-1.3,0.1-1.9,0.2c0.4,3.4,2.6,6.2,5.6,7.5
    C-18,66.9-15.8,64.1-15.4,60.7z"/>
<path class="st46" d="M-46.4,63h-10.8c-2.1,0-4,0.1-5.8,1c-4.5,2.2-7.7,6.8-7.7,12.2v12.3c0,1,0.8,1.8,1.8,1.8h34.1
    c1,0,1.8-0.8,1.8-1.8V76.1c0-5.4-3.2-10.1-7.8-12.2C-42.5,63.1-44.4,63-46.4,63z"/>
<path class="st33" d="M-46.4,63h-2.7c2.8,0,5.4,0.8,7.4,2.6c2.7,2.5,4.5,6.3,4.5,10.5v12.3c0,1-0.7,1.8-1.6,1.8h4
    c1,0,1.8-0.8,1.8-1.8V76.1c0-5.4-3.2-10.1-7.8-12.2C-42.5,63.1-44.4,63-46.4,63z"/>
<path class="st43" d="M-51.8,84.1l11.1-20.2c-1.7-0.8-3.6-1.2-5.6-1.2h-10.8c-2,0-3.9,0.4-5.6,1.2L-51.8,84.1z"/>
<path class="st42" d="M-49.9,67.6c2-0.8,3.4-2.7,3.4-5h-10.7c0,2.2,1.4,4.2,3.4,5l-1.8,9.7l3.7,6.8l3.7-6.8L-49.9,67.6z"/>
<path class="st35" d="M90.2,52.9c-0.6,5.3-5.1,9.4-10.5,9.4c-5.8,0-10.6-4.7-10.6-10.6v-6.9c0-2.6,1-4.9,2.6-6.6
    C74.1,43.7,80.4,50.3,90.2,52.9z"/>
<g>
    <path class="st25" d="M63.2,33c0,6.6-5.4,9.4-12,9.4c-6.7,0-12-2.9-12-9.4c0-3.7,3.9-8.7,7.2-12.4c1.5-1.6,2.8-2.9,3.7-3.7
        c0.7-0.7,1.1-1,1.1-1s0.4,0.4,1.1,1c0.9,0.8,2.2,2.2,3.7,3.7C59.3,24.2,63.2,29.2,63.2,33z"/>
    <path class="st25" d="M48,18.8h6.5c1.3-1.3,1.9-3.2,2.3-5.1c0.1-0.8-0.6-1.5-1.4-1.1c-1.6,0.8-2.5,0.4-3.5-0.4
        c-0.4-0.3-0.9-0.3-1.3,0c-1,0.8-1.9,1.2-3.5,0.4c-0.8-0.3-1.6,0.3-1.4,1.1C46.1,15.7,46.6,17.4,48,18.8z"/>
</g>
<path class="st47" d="M46.3,14.4c-0.1-0.8,0.6-1.5,1.4-1.1c1.6,0.8,2.5,0.4,3.5-0.4c0.4-0.3,0.9-0.3,1.3,0c1,0.8,1.9,1.2,3.5,0.4
    c0.3-0.1,0.5-0.1,0.8-0.1c-0.2-0.5-0.8-0.9-1.4-0.6c-1.6,0.8-2.5,0.4-3.5-0.4c-0.4-0.3-0.9-0.3-1.3,0c-1,0.8-1.9,1.2-3.5,0.4
    c-0.8-0.3-1.6,0.3-1.4,1.1c0.4,1.9,1,3.7,2.3,5.1h0.1C47.1,17.6,46.6,16,46.3,14.4z"/>
<circle class="st39" cx="51.2" cy="31.6" r="5.8"/>
<path class="st48" d="M46.1,32.2c0-3.2,2.6-5.8,5.8-5.8c1.4,0,2.7,0.5,3.7,1.4c-1.1-1.2-2.6-2-4.4-2c-3.2,0-5.8,2.6-5.8,5.8
    c0,1.8,0.8,3.3,2,4.4C46.6,34.9,46.1,33.6,46.1,32.2z"/>
<path class="st39" d="M55.3,19.7h-8.2c-0.6,0-1-0.4-1-1l0,0c0-0.6,0.4-1,1-1h8.3c0.6,0,1,0.4,1,1l0,0
    C56.3,19.3,55.9,19.7,55.3,19.7z"/>
<path class="st28" d="M53.3,32.3c-0.1-0.2-0.2-0.3-0.4-0.5c-0.2-0.1-0.4-0.2-0.6-0.2c0.2,0,0.4-0.1,0.6-0.2
    c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.9-0.2l0-0.7
    l-0.8,0l0,0.7l-0.5,0l-0.6,0l-0.5,0l-0.1,5.6l0.5,0l0.6,0l0.5,0l0,1.2l0.8,0l0-1.2l0,0c0.4,0,0.7-0.1,0.9-0.2
    c0.3-0.1,0.5-0.3,0.6-0.5c0.1-0.2,0.2-0.5,0.2-0.8l0,0C53.4,32.7,53.3,32.4,53.3,32.3z M52.1,30c0.1,0.1,0.2,0.2,0.2,0.4
    c0,0.2-0.1,0.4-0.2,0.5C52,31,51.8,31,51.7,31l0-1.2C51.9,29.9,52,29.9,52.1,30z M50.4,29.8l0.5,0l0,1.2l-0.5,0L50.4,29.8z
     M50.3,33.4l0-1.4l0.5,0l0,1.4L50.3,33.4z M52.3,32.8c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.1-0.5,0.2l0-1.3c0.1,0,0.2,0,0.3,0.1
    c0.1,0.1,0.2,0.1,0.3,0.2C52.3,32.5,52.3,32.6,52.3,32.8L52.3,32.8z"/>
</g>`;

const memdiv = `<g id="ปันผล">
<path class="st27" d="M73.8,64.8c-0.6-0.6-2.3-1-3.1-1h-1c-0.8,0-1.4,0-2.3,0v6.8c2.9-0.6,4.5-1.6,5.5-2.3c0.6-0.6,1-1.4,1.2-2.3
    C74,65.4,73.8,65,73.8,64.8z"/>
<g>
    <path class="st43" d="M85.7,35.5c0,0.6,0,1,0,1.4c-0.8,11.3-9.6,20.1-20.9,20.9c-0.4,0-1,0-1.4,0c0-0.6,0-1,0-1.4
        c0.4-5.5,2.9-10.6,6.6-14.3c3.7-3.7,8.8-6.1,14.3-6.6C84.7,35.5,85.1,35.5,85.7,35.5z"/>
    <path class="st43" d="M38.8,35.5c0,0.6,0,1,0,1.4c0.8,11.3,9.6,20.1,20.9,20.9c0.4,0,1,0,1.4,0c0-0.6,0-1,0-1.4
        c-0.4-5.5-2.9-10.6-6.6-14.3c-3.7-3.7-8.8-6.1-14.3-6.6C39.9,35.5,39.2,35.5,38.8,35.5z"/>
</g>
<rect x="60.5" y="27.9" class="st24" width="3.3" height="34.8"/>
<circle class="st44" cx="62.2" cy="21.3" r="18.6"/>
<circle class="st14" cx="62.2" cy="21.3" r="15.1"/>
<g>
    <path class="st18" d="M93.5,68.4c0,0-9.7,7.7-15.2,10.1c0,0-12,6.5-23.3,6.9H37.4c0,0-3.2-0.6-7.5,2.2c-0.6,0.4-1,0.8-1.6,1.2
        c-0.8,0.6-1.8,1.4-2.6,2.4L11.5,70.7c0,0,1.2-0.8,2.8-2.2c0.4-0.4,1-0.8,1.4-1.4c1.2-1,2.4-2.4,3.6-3.8c0,0,7.5-9.7,24.3-3.8
        c11.8,4.1,13.2,4.3,13.2,4.3s7.1,0.8,14,0.8c0,0,5.7,0,5.5,4.3c0,1.4-0.8,2.6-1.8,3.6c-1.4,1.2-4.1,2.6-8.9,3
        c-2,0.2-4.3,0.2-6.3,0c-5.3-0.4-11.5-1.4-12.8-0.6c0,0-1.6,1.2,4.7,2c0,0,4.7,0.6,13,0.8c0,0,15-0.6,14.4-11.1
        c0,0,5.3-3.2,8.3-4.5c0,0,8.5-5.1,10.5-1.8C97.6,60.3,100,63.8,93.5,68.4z"/>
</g>
<path class="st27" d="M73.1,67.4c-0.6-0.6-2.2-1-3-1h-1c-0.8,0-1.4,0-2.2,0v6.7c2.8-0.6,4.5-1.6,5.5-2.2c0.6-0.6,1-1.4,1.2-2.2
    C73.3,68,73.1,67.6,73.1,67.4z"/>
<path class="st45" d="M29.9,87.7c-0.6,0.4-1,0.8-1.6,1.2c-0.8,0.6-1.8,1.4-2.6,2.4L11.5,70.7c0,0,1.2-0.8,2.8-2.2
    c0.4-0.4,1-0.8,1.4-1.4L29.9,87.7z"/>

    <rect x="9.3" y="65.2" transform="matrix(0.8238 -0.5668 0.5668 0.8238 -43.5713 23.2582)" class="st28" width="12.8" height="33"/>
<circle class="st46" cx="22.2" cy="90.7" r="2.8"/>
<path class="st44" d="M68.8,22.2c-0.3-0.5-0.6-1-1.1-1.3c-0.5-0.3-1.1-0.6-1.8-0.7c0.7-0.1,1.2-0.3,1.7-0.6
    c0.5-0.3,0.8-0.7,1.1-1.2c0.2-0.5,0.4-1,0.4-1.6c0-0.9-0.2-1.7-0.5-2.3c-0.4-0.6-0.9-1.1-1.6-1.5c-0.7-0.3-1.5-0.5-2.4-0.5l0-2.1
    l-2.2,0l0,2.1l-1.4,0l-1.7,0l-1.3,0l-0.2,15.9l1.3,0l1.7,0l1.4,0l0,3.4l2.2,0l0-3.4l0,0c1,0,1.9-0.1,2.6-0.5
    c0.7-0.3,1.3-0.8,1.7-1.5c0.4-0.6,0.6-1.4,0.6-2.3l0,0C69.2,23.3,69,22.7,68.8,22.2z M65.5,15.8c0.3,0.3,0.5,0.7,0.5,1.2
    c0,0.6-0.2,1-0.5,1.3c-0.3,0.3-0.7,0.4-1.2,0.4l0-3.3C64.8,15.4,65.2,15.5,65.5,15.8z M60.7,15.3l1.4,0l0,3.4l-1.4,0L60.7,15.3z
     M60.6,25.4l0-3.8l1.4,0l0,3.8L60.6,25.4z M66.1,23.6c0,0.6-0.2,1.1-0.6,1.4c-0.3,0.3-0.8,0.4-1.3,0.4l0-3.8c0.3,0,0.6,0.1,0.9,0.2
    c0.3,0.2,0.6,0.4,0.7,0.7C66.1,22.8,66.1,23.1,66.1,23.6L66.1,23.6z"/>
</g>`;

const memchangepass = `<g id="เปลี่ยนรหัส">
<path class="st45" d="M50.7,2C33.7,2,20,15.7,20,32.7v20.4h61.5V32.7C81.5,15.7,67.8,2,50.7,2z M69.7,41.4h-38v-8.6
    c0-10.4,8.4-19,19-19s19,8.4,19,19L69.7,41.4L69.7,41.4z"/>
<path class="st14" d="M88.1,96H13.3c-2.2,0-3.9-1.8-3.9-3.9V42.5c0-2.2,1.8-3.9,3.9-3.9h75c2.2,0,3.9,1.8,3.9,3.9v49.5
    C92,94.2,90.3,96,88.1,96z"/>
<g>
    <path class="st46" d="M23.7,38.6H13.3c-2.2,0-3.9,1.8-3.9,3.9v49.5c0,2.2,1.8,3.9,3.9,3.9h10.4L23.7,38.6L23.7,38.6z"/>
    <path class="st46" d="M77.7,38.6h10.4c2.2,0,3.9,1.8,3.9,3.9v49.5c0,2.2-1.8,3.9-3.9,3.9H77.7V38.6L77.7,38.6z"/>
</g>
</g>`;

const memlogout = `<g id="ออกจากระบบ">
<rect x="7" y="3" class="st47" width="56.6" height="84.9"/>
<polygon class="st46" points="45.4,97 7,87.9 7,3 46,12 	"/>
<line class="st48" x1="89" y1="45.5" x2="54.4" y2="45.5"/>
<path class="st49" d="M79.5,31.9"/>
<polyline class="st50" points="79.5,31.9 90,45.5 79.5,57.8 	"/>
<path d="M40,52L40,52c-1.1,0-2-0.9-2-2v0c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v0C42,51.1,41.1,52,40,52z"/>
</g>`;

const memWelfare = `<path
class="cls-1"
d="M86.88,47.56l.53-1.69,3.06-10.25.37-1.22A5.14,5.14,0,0,1,97,30.88L92.08,57.33a15.48,15.48,0,0,1-5.86,7.79l-10.73,7.4a4.72,4.72,0,0,0-2.14,2.79l-1.81,9.1-.3.4L57.52,74.31l.06-.08a8.4,8.4,0,0,0,.76-3l.16-2.84A14.19,14.19,0,0,1,67.89,57l.92-.32a10.21,10.21,0,0,0,3.84-2.41l4.69-4.69,1.34-1.34a6.34,6.34,0,0,1,8.2-.65Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-2"
d="M90.47,35.62,87.41,45.87l-.53,1.69a6.33,6.33,0,0,0-4.12-1.2l2-6.58.5-1.67a4.35,4.35,0,0,1,5.23-2.49Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-2"
d="M84.73,39.78l-2,6.58a6.36,6.36,0,0,0-4.08,1.85l-1.34,1.34L79,44.05a5.82,5.82,0,0,1,5.62-4.31Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-1"
d="M16.7,46.36a6.36,6.36,0,0,1,4.08,1.85l1.34,1.34,4.69,4.69a10.21,10.21,0,0,0,3.84,2.41l.92.32A14.19,14.19,0,0,1,41,68.39l.16,2.84a8.4,8.4,0,0,0,.76,3l.06.08L28.12,84.89l-.2-.26-1.81-9.32A4.72,4.72,0,0,0,24,72.52l-10.73-7.4a15.48,15.48,0,0,1-5.86-7.79L2.46,30.88A5.14,5.14,0,0,1,8.62,34.4L9,35.62l3.06,10.25.53,1.69a6.33,6.33,0,0,1,4.12-1.2Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-2"
d="M20.5,44.05l1.62,5.5-1.34-1.34a6.36,6.36,0,0,0-4.08-1.85l-2-6.58.15,0A5.82,5.82,0,0,1,20.5,44.05Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-2"
d="M14.73,39.78l2,6.58a6.33,6.33,0,0,0-4.12,1.2l-.53-1.69L9,35.62H9a4.35,4.35,0,0,1,5.23,2.49Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-3"
d="M41.88,75.54a1.3,1.3,0,0,1-1.19-.76,9.49,9.49,0,0,1-.87-3.48l-.17-2.78A12.85,12.85,0,0,0,31.14,58.2l-.92-.32a11.46,11.46,0,0,1-4.33-2.71l-6-6a5,5,0,0,0-6-.84l4.22,3.85a37.61,37.61,0,0,1,4.58,5,1.31,1.31,0,0,1-2.08,1.58,34.93,34.93,0,0,0-4.26-4.67l-5.38-4.91a1.31,1.31,0,0,1-.08-1.85l0,0a7.17,7.17,0,0,1,.93-.79,7.66,7.66,0,0,1,9.89.79l6,6a8.83,8.83,0,0,0,3.34,2.09l.92.32A15.46,15.46,0,0,1,42.26,68.21a.34.34,0,0,0,0,.1l.16,2.84a7.19,7.19,0,0,0,.63,2.54,1.31,1.31,0,0,1-1.18,1.85Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-3"
d="M57.58,75.54a1.31,1.31,0,0,1-1.31-1.31,1.51,1.51,0,0,1,.12-.55A6.89,6.89,0,0,0,57,71.15l.16-2.84a.34.34,0,0,0,0-.1A15.45,15.45,0,0,1,67.46,55.73l.92-.32a8.85,8.85,0,0,0,3.34-2.1l6-6a7.65,7.65,0,0,1,9.9-.78,6.89,6.89,0,0,1,.92.78,1.3,1.3,0,0,1,0,1.86l0,0-5.38,4.91a34.93,34.93,0,0,0-4.26,4.67,1.31,1.31,0,0,1-2.08-1.58,37,37,0,0,1,4.58-5l4.22-3.85a5,5,0,0,0-6,.83l-6,6a11.59,11.59,0,0,1-4.33,2.71l-.92.33a12.82,12.82,0,0,0-8.51,10.31l-.17,2.78a9.49,9.49,0,0,1-.87,3.48,1.3,1.3,0,0,1-1.19.76Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-4"
d="M73.83,86.79V99.36L54,86.23V71.58l3.56,2.73,13.72,10.5Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-4"
d="M45.5,71.58V86.23L25.63,99.36V86.79l2.49-1.9L41.94,74.31Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-5"
d="M57.52,18c.37-.09.73-.18,1-.28h0c12.17,4.78,17.63,20.62,8.64,31.93h0a.82.82,0,0,0-.08.91,6.47,6.47,0,0,1,.79,3.1A1.33,1.33,0,0,1,66.57,55a6.35,6.35,0,0,1-4.52-1.88L62,53.06a4.25,4.25,0,0,0-3-1.25H41.14A4.42,4.42,0,0,0,38,53.11h0A6.37,6.37,0,0,1,33.47,55a1.33,1.33,0,0,1-1.34-1.32h0a6.42,6.42,0,0,1,.95-3.36.71.71,0,0,0,0-.82h0c-8.82-11.31-3.32-27,8.78-31.75a31.1,31.1,0,0,0,8.38,1,35,35,0,0,0,7.33-.7Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-6"
d="M61,14.17a.15.15,0,0,1,0,.07c-.24.84-5,1.51-10.81,1.51s-10.56-.67-10.81-1.51a.15.15,0,0,1,0-.07c0-.59,2.19-1.1,5.45-1.37a7.18,7.18,0,0,0,3.34.8H51.8a7.14,7.14,0,0,0,3.39-.83c3.46.26,5.82.79,5.82,1.4Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-5"
d="M60.69,5,58.31,9.62a7.31,7.31,0,0,1-6.51,4l1.48-7.78s-2.47-2-6.79.13a5.53,5.53,0,0,1,2.89-2.44,7.11,7.11,0,0,1,6.53.65C57.2,5,59.1,5.83,60.69,5Z"
transform="translate(-2.46 -3)"
/>
<circle class="cls-6" cx="47.73" cy="34.03" r="9.77" />
<path
class="cls-6"
d="M44.82,12.8a7.3,7.3,0,0,1-3.41-3.71L39.7,5s2.47,3.09,6.79,1,6.79-.13,6.79-.13L51.8,13.6H48.16A7.18,7.18,0,0,1,44.82,12.8Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-6"
d="M59.33,19l0,0a.9.9,0,0,0-.05-.09l-1-1.38,0,0-.14-.14s0,0,0,0h0L58,17.17l-.08,0-.09,0-.09,0-.08,0h-.28l-.09,0-.09,0-.08,0-.09.05h0s0,0,0,0l-.07.06-.07.08,0,0-.62.83a14.16,14.16,0,0,0-2.49,5.52,1,1,0,0,0,.74,1.14l.2,0a1,1,0,0,0,.93-.76,12.34,12.34,0,0,1,2-4.53l.18.25,0,0a25.37,25.37,0,0,1,2.85,7.28,1,1,0,0,0,.93.74.78.78,0,0,0,.22,0,1,1,0,0,0,.72-1.14h0A27.67,27.67,0,0,0,59.33,19Z"
transform="translate(-2.46 -3)"
/>
<path
class="cls-5"
d="M51.58,36.11a2,2,0,0,0,1.17-1.76,2.1,2.1,0,0,0-.82-1.78A3.09,3.09,0,0,0,50.52,32V30.37H49.43V32c-.52,0-1.32,0-2.43.09V41.4h2.43v2.07h1.09V41.36a3.73,3.73,0,0,0,1.84-.71,2.44,2.44,0,0,0,1-2A2.42,2.42,0,0,0,51.58,36.11Zm-.49-1.7a1,1,0,0,1-.57,1V33.52A.91.91,0,0,1,51.09,34.41Zm-2.43-1,.77,0v2.3c-.27,0-.53,0-.77,0Zm0,6.63V36.91l.77,0v3.16C49.23,40.05,49,40,48.66,40Zm2.43-.33a1.88,1.88,0,0,1-.57.26V37a1.65,1.65,0,0,1,.61.26,1.41,1.41,0,0,1,.46,1.17A1.45,1.45,0,0,1,51.09,39.67Z"
transform="translate(-2.46 -3)"
/>`;
const SvgNew = {
    memstatus,
    memshare,
    memloan,
    memdep,
    memkep,
    memcoll,
    memgain,
    memdiv,
    memchangepass,
    memlogout,
    memWelfare
};

export default SvgNew;
