import React, { useState, useRef, useEffect, Fragment } from "react";

export const FormGroup = ({ className, children }) => {
    return (
        <div
            className={`w-full mx-auto  my-2 intro-x ${className && className}`}
        >
            {children && children}
        </div>
    );
};
export const SpanInputDesc = ({ className, children }) => {
    return (
        <span
            className={`text-xs xl:text-base text-gray-600 lg:text-base md:text-base ml-5 font-light flex w-full text-left mt-1 intro-x ${
                className && className
            } `}
        >
            {children && children}
        </span>
    );
};
export const LabelInput = ({ className, children, HtmlFor }) => {
    return (
        <label
            className={`text-left text-sm  md:text-lg lg:text-xl ml-5 text-gray-600 font-light intro-x ${
                className && className
            }   `}
            htmlFor={HtmlFor && HtmlFor}
        >
            {children && children}
        </label>
    );
};

export const InputMember = ({
    name,
    id,
    className,
    Inputref,
    value,
    placeholder,
    type,
    maxLength,
    onBlur,
    ErrorForm,
    dataMask,
    dataInputmask,
}) => {
    return (
        <input
            className={`

    flex justify-center
    pl-3   rounded-lg  px-4 py-3 mt-1 my-1 border-2 focus:outline-none bg-white w-full intro-x ${
        className && className
    }   ${
                ErrorForm
                    ? " border-red-600  focus:border-gray-700"
                    : "border-custom-400 focus:border-custom-700 "
            }`}
            type={type && type}
            name={name && name}
            id={id && id}
            maxLength={maxLength && maxLength}
            placeholder={placeholder && placeholder}
            // onKeyPress={e => isInputNumber(e)}
            onBlur={onBlur && onBlur}
            ref={Inputref && Inputref}
            value={value && value}
            data-inputmask={dataInputmask && dataInputmask}
            data-mask={dataMask && dataMask}
        />
    );
};

export const ErrorSpan = ({ children, className }) => {
    return (
        <span
            className={`text-red-600 my-1 font-light italic text-xs md:text-sm lg:text-base ${
                className && className
            } `}
        >
            {children && children}
        </span>
    );
};
