import React, { Component } from "react";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <>
                    <div className="text-center m-2 bg-white">
                        <p className="m-auto text-center">
                            <img
                                src="./mediafiles/images/Utils/cooplogo.png"
                                itemProp="image"
                                alt={`โลโก้${window.config.coopNameTH}`}
                                className="w-40 md:w-56 lg:w-60  rounded-full shadow-sm  m-auto "
                            />

                            <div className="  pl-2 pt-2 text-center py-2">
                                <h1 className="text-sm md:text-2xl  text-gray-400 lg:text-4xl xl:text-3xl font-bold">
                                    {window.config.coopNameTH}
                                </h1>
                                <h2 className="text-xs md:text-lg   text-gray-400 lg:text-2xl  xl:text-lg font-bold">
                                    {window.config.coopNameEN}
                                </h2>
                            </div>
                        </p>

                        <i className="fas fa-exclamation-circle fa-6x text-red-500 shadow-sm rounded-full"></i>
                        <h2 className="text-base md:text-xl lg:text-2xl mt-1 text-rose-500 font-semibold">
                            ขออภัย! หน้าเพจนี้ไม่พร้อมให้บริการชั่วคราว
                            กรุณาลองใหม่ในภายหลัง
                        </h2>

                        <h2
                            className="text-sm lg:text-base text-gray-600 font-medium w-36 flex text-center m-auto my-2
                            cursor-pointer
                            "
                            onClick={() => location.reload()}
                        >
                            <i className="fas fa-redo fa-md"></i>&nbsp; ลองใหม่
                        </h2>

                        <div className="border-b-2 border-gray-50 my-1"></div>
                        {/* <hr /> */}
                        <a href={`/home`}>
                            <h2 className="text-sm lg:text-base text-gray-700 font-medium w-36 flex text-center m-auto ">
                                <i className="fas fa-home fa-md"></i>&nbsp;
                                กลับหน้าหลัก
                            </h2>
                        </a>
                        {/* <p>
                            <details
                                style={{ whiteSpace: "pre-wrap" }}
                                className="text-center cursor-pointer"
                            >
                                {this.state.error &&
                                    this.state.error.toString()}
                                <br />
                                {this.state.errorInfo.componentStack}
                            </details>
                        </p> */}
                    </div>
                </>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
