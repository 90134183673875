import { MemWelfareConstant } from "../constants";

const initialState = {
    fetching: false,
    fetchSuccess: false,
    fetchFailure: false,
    curKey:'',
    data: []
};

export function feedMemWelfare(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case MemWelfareConstant.FEED_REQUEST:
            return {
                fetching: true,
                fetchSuccess: false,
                fetchFailure: false
            };
        case MemWelfareConstant.FEED_SUCCESS:
            return {
                fetchSuccess: true,
                fetching: false,
                fetchFailure: false,
                data: payload
            };
        case MemWelfareConstant.FEED_FAILURE:
            return {
                fetching: false,
                fetchSuccess: false,
                fetchFailure: true
            };
        case MemWelfareConstant.TABS_CHANGES:
            return {
                fetchSuccess: true,
                fetching: false,
                fetchFailure: false,
                curKey:payload.curKey,
                data: payload
            };
        default:
            return state;
    }
}
