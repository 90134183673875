export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    PASS_REQUEST: 'USERS_PASS_REQUEST',
    PASS_SUCCESS: 'USERS_PASS_SUCCESS',
    PASS_FAILURE: 'USERS_PASS_FAILURE',

    REGIS_REQUEST: 'USERS_REGIS_REQUEST',
    REGIS_SUCCESS: 'USERS_REGIS_SUCCESS',
    REGIS_FAILURE: 'USERS_REGIS_FAILURE',

    FORGET_REQUEST: 'USERS_FORGET_REQUEST',
    FORGET_SUCCESS: 'USERS_FORGET_SUCCESS',
    FORGET_FAILURE: 'USERS_FORGET_FAILURE',
};
