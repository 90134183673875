import { lazy } from "react";

const InfoRoute = [
    {
        path: "/home",
        exact: true,
        layout: "info",
        component: lazy(() => import("../pages/Home/Home")),
    },
    {
        path: "/list/:topicId",
        exact: true,
        layout: "info",
        component: lazy(() => import("../pages/List/List")),
    },
    {
        path: "/show/:topicId",
        exact: true,
        layout: "info",
        component: lazy(() => import("../pages/Show/Show")),
    },
    {
        path: "/board",
        exact: true,
        layout: "info",
        component: lazy(() => import("../pages/Board/Board")),
    },
    {
        path: "/boardPost",
        exact: true,
        layout: "info",
        component: lazy(() => import("../pages/Board/Board_post")),
    },
    {
        path: "/boardShow/:topicId",
        exact: true,
        layout: "info",
        component: lazy(() => import("../pages/Board/Board_show")),
    },
    {
        path: "/demand",
        exact: true,
        layout: "info",
        component: lazy(() => import("../pages/Demand/Demand")),
    },
    {
        path: "/showdemand/:topicId",
        exact: true,
        layout: "info",
        component: lazy(() => import("../pages/Demand/ShowDemand")),
    },

    {
        path: "/load",
        exact: true,
        // layout: "info",
        // component: lazy(() => import("../pages/ImgPreview/imgpreview"))
        component: lazy(() => import("../components/Skeletion/MainLoading")),
    },
    {
        path: "/colors",
        exact: true,
        // layout: "info",
        // component: lazy(() => import("../pages/ImgPreview/imgpreview"))
        component: lazy(() => import("../pages/ColorPlate/ColorPlate")),
    },
];

export default InfoRoute;
