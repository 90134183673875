import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import AuthenticationBoxes from "../Utils/Auth/AuthenticationBoxes";
import InSearching from "./InSearching";

const SmartSearch = () => {
    const [ModalSearchStatus, setModalSearchStatus] = useState(false);

    const OnToggleModalSearch = () => {
        setModalSearchStatus((state) => {
            return !state;
        });
        return;
    };
    return (
        <>
            <Transition appear show={ModalSearchStatus} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={OnToggleModalSearch}
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className=" h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="  inline-block w-full max-w-md lg:max-w-xl xl:max-w-2xl p-2 xl:p-4 my-3 xl:my-8  text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg  text-center font-medium leading-6 text-gray-900"
                                >
                                    ค้นหาเนื้อหาภายในเว็บไซต์
                                </Dialog.Title>
                                <div className="text-right text-lg tex-gray-800 cursor-pointer">
                                    <i
                                        onClick={OnToggleModalSearch}
                                        className="fas fa-window-close"
                                    ></i>
                                </div>
                                {/* ค้นหา */}
                                <InSearching />
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            <div className="smart-search">
                <button
                    onClick={OnToggleModalSearch}
                    type="button"
                    className="outline-none group leading-6 font-medium flex justify-end w-full
                    mr-0 justify-content-end justify-items-end py-1 px-2 rounded-md shadow xl:w-4/12 lg:w-4/12 mt-5 bg-white m-auto"
                >
                    {/* <i className="fas fa-search"></i>
                    <span className="text-gray-600">
                        ค้นหา
                        <span className="hidden sm:inline">เนื้อหาต่างๆ</span>
                    </span> */}
                    <a
                        type="submit"
                        className="rounded-lg bg-blue-300 text-black font-medium text-sm
                md:text-base lg:text-lg shadow p-1"
                    >
                        ค้นหา
                    </a>
                </button>
            </div>
        </>
    );
};

export default SmartSearch;
