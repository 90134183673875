import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { userActions } from '../actions';
// import { useStyle, useScript } from "../../helpers";
import { HeaderNavAction } from "../actions";
import { Link } from "react-router-dom";
import LastUpdateWebMember from "../component/utils/LastUpdateWebMember";
// import "./Header.css";

const Header = () => {
    const HeaderNav = useSelector((state) => state.headerNav);
    const authentication = useSelector((state) => state.authentication);
    const dispatch = useDispatch();
    // const toggleMobile = () => {
    //     // console.log("toggleMobile");
    //     dispatch(HeaderNavAction.onToggleOpened(HeaderNav.opened));
    //     $("#sidebar-wrapper").width(250);
    //     // $("#wrapper").css("padding-left", "250px");
    //     // if (window.matchMedia('screen and (max-width: 768px)').matches) {
    //     //     $("#wrapper").css("padding-left", "unset");
    //     // }else{
    //     //     $("#wrapper").css("padding-left", "250px");
    //     // }
    //     $(this).css("background-color", "#7a879d");
    //     if ($("#sidebar-wrapper").width() > 0) {
    //         $("#sidebar-wrapper").width(0);
    //         $("#wrapper").css("padding-left", "unset");
    //         $(this).css("background-color", "#7a879d");
    //     }
    // };

    // const toggleDesktop = () => {
    //     // console.log("toggleDesktop");
    //     dispatch(HeaderNavAction.onToggleCollapsed(HeaderNav.collapsed));

    //     $("#sidebar-wrapper").width(250);
    //     $("#wrapper").css("padding-left", "250px");
    //     $(this).css("background-color", "#7a879d");

    //     // if ($("#sidebar-wrapper").width() > 0) {
    //     //     $("#sidebar-wrapper").width(0);
    //     //     $("#wrapper").css("padding-left", "unset");
    //     //     $(this).css("background-color", "#7a879d");
    //     // }
    //     if ($("#sidebar-wrapper").width() > 80) {
    //         $("#sidebar-wrapper").width(80);
    //         // $("#wrapper").css("padding-left", "unset");
    //         $("#wrapper").css("padding-left", "80px");
    //         $(this).css("background-color", "#7a879d");
    //     }
    // };

    return (
        <header className="z-10">
            <div className="h-22 z-40  py-2 bg-blue-200 shadow-md dark:bg-gray-800 fixed top-0 left-0 w-full ">
                <div className="flex py-1 pt-1 text-purple-600 dark:text-purple-300">
                    <Link
                        to={`/member/logon`}
                        className="mt-1 ml-6 text-md xl:text-xl lg:text-xl md:text-lg font-bold text-gray-800 dark:text-gray-200"
                    >
                        <i className="fas fa-user-alt fa-1x"></i>
                        &nbsp;ระบบสมาชิก
                    </Link>
                    {/* <!-- Mobile hamburger --> */}
                    <button
                        className="border-2 border-purple-700 px-2 mr-5 ml-4 rounded lg:hidden focus:outline-none focus:shadow-outline-purple"
                        onClick={() => {
                            // console.log("clicked");
                            dispatch(
                                HeaderNavAction.onToggleOpened(HeaderNav.opened)
                            );
                        }}
                        aria-label="Menu"
                    >
                        เมนู
                        <svg
                            class="pl-1 w-6 h-6 inline-block"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="flex pt-16 flex-wrap ">
                <div className="flex justify-center  w-full md:w-1/6">
                    <img
                        src="./mediafiles/images/Utils/cooplogo.png"
                        className="w-28 h-28 xl:w-40 xl:h-40 animate-pulse rounded-full "
                        alt={`โลโก้ ${window.config.coopNameTH}`}
                    />
                </div>
                <div className="w-full md:w-5/6 lg:pl-12">
                    <h1 className="intro-x  text-center lg:text-left text-blue-600 no-m m-t-xs py-2 text-base xl:text-4xl lg:text-2xl md:text-xl font-bold  overflow-hidden">
                        {window.config.coopNameTH}
                    </h1>
                    <h1 className="intro-x no-m  text-green-600 text-center lg:text-left text-xs xl:text-lg lg:text-lg  md:text-sm font-bold  overflow-hidden">
                        {window.config.coopNameEN}
                    </h1>
                    <h2 className="intro-x mb-0 text-black text-center lg:text-left font-normal text-sm md:text-base lg:text-lg xl:text-2xl">
                        <i className="fas fa-user-circle fa-1x text-black"></i>{" "}
                        <label className="text-black font-light">ทะเบียน</label>
                        {authentication &&
                            authentication.loggedIn &&
                            ` ${authentication.user.membership_no}  : ${authentication.user.member.member_name}  ${authentication.user.member.member_surname}`}
                    </h2>
                    {/* <div>
                        <LastUpdateWebMember />
                    </div> */}
                </div>
            </div>
        </header>
    );
};

export default Header;
