import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userActions } from "../../../../Member/actions";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { Wordcount } from "../../../../helpers";
import {
    InputMember,
    LabelInput,
    SpanInputDesc,
} from "../../../../Member/pages/Memregis/utils";
function AuthenticationBoxes({ closeModal }) {
    const dispatch = useDispatch();
    const authentication = useSelector((state) => state.authentication);
    const [passwordeye, setpasswordeye] = useState(false);
    useEffect(() => {
        setloading(false);
        if (authentication.loggedIn) {
            // history.push("/member/status");
            // NotificationManager.error("กรุณากรอก ทะเบียนสมาชิก", "Error", 5000);
        }
    }, [authentication]);

    const [loading, setloading] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    useEffect(() => {
        if (errors.membership_no) {
            NotificationManager.error("กรุณากรอก ทะเบียนสมาชิก", "Error", 5000);
        } else if (errors.mem_password) {
            NotificationManager.error("กรุณากรอก รหัสผ่าน", "Error", 5000);
        }
    }, [errors]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        // alert('ขออภัยระบบกำลังพัฒนา');
        setloading(true);
        await dispatch(
            userActions.login({
                ...data,
            })
        );
    };
    const isInputNumber = (e) => {
        var char = String.fromCharCode(e.which);
        if (!/[0-9]/.test(char)) {
            e.preventDefault();
        }
    };
    return (
        <Fragment>
            <div className="text-center p-2 rounded bg-white">
                {authentication.loggedIn ? (
                    <Fragment>
                        <div className="text-center mt-2">
                            <button
                                className="rouneded p-3 bg-red-400 shadow"
                                onClick={() => {
                                    closeModal;
                                }}
                            >
                                <h3 className="text-base text-gray-800 font-medium">
                                    ปิดหน้าต่าง
                                </h3>
                            </button>
                            {/* <Link to={"/member/status"}>
                                <button className="rouneded p-3 bg-blue-400 shadow">
                                    <h3 className="text-base text-gray-800 font-medium">
                                        ตรวจสอบข้อมูล Online
                                    </h3>
                                </button>
                            </Link> */}
                        </div>
                    </Fragment>
                ) : (
                    <form
                        className="login100-form validate-form flex-sb flex-w"
                        name="form1"
                        method="post"
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                    >
                        <div className="m-2 text-center">
                            <img
                                src="./mediafiles/images/Utils/cooplogo.png"
                                className="rounded-full w-28 h-28 text-center m-auto mb-1"
                                itemProp="image"
                                alt={`โลโก้สหกรณ์ออมทรัพย์${window.config.coopNameTH}`}
                            />
                            {/* ----------- Mobile-------------------------------------------- */}
                            <h2 className=" block  text-sm md:text-base lg:text-lg text-custom-800 font-medium mb-0">
                                {window.config.coopNameTH}
                            </h2>
                            {/* <h2 className=" block  text-xs md:text-base text-custom-800 mb-0">
                                {window.config.coopNameEN}
                            </h2> */}
                        </div>

                        <div className="m-2 text-center">
                            <LabelInput
                                className="block"
                                HtmlFor={`membership_no`}
                            >
                                ทะเบียนสมาชิกสหกรณ์ฯ
                            </LabelInput>
                            {/* <img
                                className="w-10 h-10 absolute left-3 -bottom-3/6 md:top-16 top-14 transform -translate-y-1/2 text-gray-400 intro-x"
                                src="mediafiles/images/Utils/profile1.svg"
                            /> */}
                            <InputMember
                                className=""
                                type="text"
                                name="membership_no"
                                id="membership_no"
                                maxLength={7}
                                placeholder="กรุณากรอกหมายเลขสมาชิก"
                                // onKeyPress={e => isInputNumber(e)}
                                onBlur={(e) => Wordcount(e)}
                                Inputref={register({
                                    required: true,
                                })}
                                ErrorForm={
                                    errors.membership_no?.type === "required" &&
                                    true
                                }
                            />
                            {errors.membership_no && (
                                <span className="text-red-600 text-sm">
                                    กรุณากรอกหมายเลขสมาชิก
                                </span>
                            )}
                            <SpanInputDesc>
                                กรุณากรอกหมายเลขสมาชิกให้ครบทุกหลัก เช่น 012345
                            </SpanInputDesc>
                        </div>
                        {/* <div className="m-2 text-center">
                            <input
                                className=" w-full rounded-lg border-2 border-custom-200  bg-white p-3
                                focus:bg-white
                                 focus:shadow-lg
                                focus:border-custom-400"
                                placeholder="********"
                                name="mem_password"
                                id="mem_password"
                                type="password"
                                ref={register({ required: true })}
                            />
                        </div> */}
                        <div
                            className="m-2 relative  text-right"
                            data-validate="กรุณาใส่รหัสผ่าน"
                        >
                            <InputMember
                                className=""
                                type={passwordeye ? "text" : "password"}
                                name="mem_password"
                                id="mem_password"
                                placeholder="กรุณาระบุรหัสผ่าน"
                                Inputref={register({
                                    required: true,
                                })}
                                ErrorForm={
                                    errors.mem_password?.type === "required" &&
                                    true
                                }
                            />
                            {errors.mem_password && (
                                <span className="text-red-600 text-sm">
                                    กรุณากรอกรหัสผ่าน
                                </span>
                            )}
                            <span
                                onClick={() => {
                                    setpasswordeye((state) => {
                                        return !state;
                                    });
                                }}
                                className="text-gray-700
                                 absolute   text-base w-10 h-10  right-2 lg:right-3 xl:right-3  top-4 md:top-3  "
                            >
                                <i
                                    className={`fas fa-${
                                        passwordeye ? "eye" : "eye-slash"
                                    } fa-md`}
                                />
                            </span>
                        </div>
                        <div className="m-2 text-center">
                            <input
                                className=" "
                                type="checkbox"
                                name="remember"
                                id="remember"
                                ref={register}
                            />{" "}
                            ให้ฉันอยู่ในระบบ
                            <span className="focus-input100" />
                        </div>

                        {/* <div className="m-2 text-center">
                            <button
                                type="submit"
                                name="Submit"
                                id="submit"
                                className="text-black text-sm lg:text-lg md:text-base font-normal
                                 p-2 rounded-md shadow-sm  bg-blue-400
                                hover:bg-orange-200 hover:shadow-lg

                                w-5/6
                                "
                            >
                                {loading && (
                                    <Fragment>
                                        <i
                                            className="fas fa-spinner
                                     animate-spin fa-lg text-black"
                                        ></i>{" "}
                                        &nbsp;
                                    </Fragment>
                                )}
                                &nbsp;{" "}
                                <font className="hover:underline">
                                    เข้าสู่ระบบ
                                </font>
                            </button>
                        </div> */}
                        <div
                            className="flex m-auto justify-center intro-x m-2 text-center"
                            style={{ justifyContent: "center" }}
                        >
                            <button
                                type="submit"
                                className="w-3/6 md:w-1/4 lg:w-1/4 xl:w-1/4 m-auto rounded-full bg-gradient-to-r
                                            from-custom-400 to-custom-500
                                            outline-none
                                            hover:from-custom-500 hover:to-custom-400
                                             shadow text-sm md:text-base lg:text-lg  font-medium text-white
                                    hover:bg-custom-500 hover:shadow-lg hover:underline p-3
                                    transition duration-300 ease-in-out  transform hover:translate-y-1 hover:translate-x-1  hover:scale-105

                                    "
                                name="Submit"
                                id="submit"
                            >
                                {" "}
                                {loading && (
                                    <Fragment>
                                        <i
                                            className="fas fa-spinner
                                     animate-spin fa-lg text-black"
                                        ></i>{" "}
                                        &nbsp;
                                    </Fragment>
                                )}{" "}
                                เข้าสู่ระบบ{" "}
                            </button>
                        </div>
                        <div className="flex flex-wrap mt-2">
                            <div className="text-black w-full p-2">
                                <Link
                                    to="/member/register"
                                    // onClick={() => {
                                    //     alert("อยู่ระหว่างพัฒนา");
                                    // }}
                                    className="text-gray-700 hover:text-orange-500 hover:font-normal"
                                >
                                    เข้าใช้งานครั้งแรก (ลงทะเบียน)
                                </Link>
                            </div>
                            <div className=" w-full text-black ">
                                <Link
                                    to="/member/forget"
                                    // onClick={() => {
                                    //     alert("อยู่ระหว่างพัฒนา");
                                    // }}
                                    className="text-gray-700 hover:text-orange-500 hover:font-normal"
                                >
                                    ลืมรหัสผ่าน
                                </Link>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </Fragment>
    );
}

export default AuthenticationBoxes;
