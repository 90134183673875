import { FeedfooterConstants } from '../constants';
import { feeddataService } from '../services';

function feedfooterData(url) {
    return (dispatch) => {
        dispatch(fetching(true));

        feeddataService.feedData(url)
            .then(
                data => dispatch(fetchSuccess(data)),
                err => dispatch(fetchFailure(true))
            );
    }
}
function feedfooterDataPost(url, body = {}) {
    return (dispatch) => {
        dispatch(fetching(true));
        feeddataService.feedDataPost(url, body)
            .then(
                data => dispatch(fetchSuccess(data)),
                err => dispatch(fetchFailure(err))
            )
    }
}


const fetching = (bool) => {
    return {
        type: FeedfooterConstants.FEEDFOOTER_REQUEST,
        fetching: bool
    }
};

const fetchSuccess = (data) => {
    return {
        type: FeedfooterConstants.FEEDFOOTER_SUCCESS,
        payload: data
    }
}

const fetchFailure = (bool) => {
    return {
        type: FeedfooterConstants.FEEDFOOTER_FAILURE
    }
}

export const feedfooterActions = {
    feedfooterData,
    feedfooterDataPost
    // feedHomepage,
}
